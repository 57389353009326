<template>
  <tr>
    <td>
      {{ prm }}
    </td>
    <td colspan="50" rowspan="2" class="text-left pa-4 text">En cours...</td>
  </tr>
</template>

<script>
  export default {
    name: 'OptimisationProfileActive',
    props: {
      prm: {
        type: String,
        required: true,
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import 'src/client/style/table.scss';
  .text {
    color: var(--secondary);
  }
</style>
