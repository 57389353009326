<template>
  <td>
    <div
      v-for="(transport, i) in transports"
      :key="'datas' + i"
      class="mt-5 mb-5"
    >
      <div
        v-if="
          transport.acheminementType ===
            eAcheminementType.eAjustedAcheminement && isPowerCustomOpen
        "
        class="d-flex justify-center"
      >
        <div v-if="transport.properties.puissances[temporalClass]">
          <AppInputNumber
            class="input-number"
            :value="customTransport.properties.puissances[temporalClass]"
            :placeholder="
              transport.properties.puissances[temporalClass].toString()
            "
            :min="0"
            :height="20"
            :disabled="isPowerCustomLoading"
            @input="$emit('updatePower', $event, temporalClass)"
            @blur="$emit('updatePowersOnBlur')"
          />
        </div>
        <div
          v-else-if="!transport.properties.puissances[temporalClass]"
          :style="{ color: 'transparent' }"
        >
          -
        </div>
      </div>

      <div v-else :class="[cssAcheminementType(transport), 'mt-5 mb-5']">
        <AppNumber :value="transport.properties.puissances[temporalClass]" />
      </div>
    </div>
  </td>
</template>

<script>
  import AppInputNumber from '../app/AppInputNumber';
  import AppNumber from '../app/AppNumber';
  import { eAcheminementType } from '../../services/TransportBudget.mapper';

  export default {
    name: 'OptimizationPowers',
    components: {
      AppInputNumber,
      AppNumber,
    },
    props: {
      transports: {
        type: Array,
        Required: true,
      },
      temporalClass: {
        type: String,
        Required: true,
      },
      isPowerCustomOpen: {
        type: Boolean,
      },
      isPowerCustomLoading: {
        type: Boolean,
      },
      customTransport: {
        type: Object,
      },
    },
    data() {
      return {
        eAcheminementType,
      };
    },
    methods: {
      cssAcheminementType(transport) {
        if (
          transport.acheminementType === eAcheminementType.eCurrentAcheminement
        )
          return 'current';

        const actualPower = this.transports.find(
          (transport) =>
            transport.acheminementType ===
            eAcheminementType.eCurrentAcheminement
        )?.properties.puissances[this.temporalClass];

        if (actualPower === transport.properties.puissances[this.temporalClass])
          return 'current';

        if (
          transport.acheminementType ===
          eAcheminementType.eOptimizedAcheminement
        )
          return 'optimize';
        if (
          transport.acheminementType === eAcheminementType.eAjustedAcheminement
        )
          return 'custom';
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import 'src/client/style/table.scss';
  @import 'src/client/style/transport-type.scss';

  .input-number {
    max-width: 2rem;
    font-size: 0.9em;
    padding: 0;
    margin: 0;
  }
</style>
