<template>
  <table class="site-page-technical-data">
    <thead>
      <tr>
        <th class="site-page-technical-data__first-column">Données clients</th>
        <th
          v-for="(temporalClass, i) in temporalClasses"
          :key="'temporalClass' + i"
        >
          {{ temporalClass }}
        </th>
        <th class="site-page-technical-data__last-column">TOTAL</th>
      </tr>
    </thead>

    <tbody>
      <tr>
        <td class="site-page-technical-data__first-column">
          Consommation (MWh)
        </td>
        <td
          v-for="(temporalClass, i) in temporalClasses"
          :key="'consumption' + i"
        >
          <AppNumber
            :value="analyse.consumptionByTemporalClass[temporalClass]"
          />
        </td>
        <td>
          <AppNumber :value="analyse.consommationTotale" />
        </td>
      </tr>
      <tr>
        <td class="site-page-technical-data__first-column">
          Puissance max atteinte (kW)
        </td>
        <td
          v-for="(temporalClass, i) of temporalClasses"
          :key="'max-power-value' + i"
        >
          {{
            Math.round(analyse.puissanceMaxParClasse[temporalClass].value) /
            1000
          }}
        </td>
      </tr>
      <tr>
        <td class="site-page-technical-data__first-column">Date</td>
        <td
          v-for="(temporalClass, i) of temporalClasses"
          :key="'max-power-date' + i"
        >
          {{
            formatDate(
              analyse.puissanceMaxParClasse[temporalClass].measureDatetime
            )
          }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
  import moment from 'moment-timezone';
  import AppNumber from '../../app/AppNumber';

  export default {
    name: 'SitePageTechnicalData',
    components: {
      AppNumber,
    },
    props: {
      analyse: {
        type: Object,
        Required: true,
      },
      temporalClasses: {
        type: Array,
        Required: true,
      },
    },
    methods: {
      formatDate(date) {
        return moment(date).format('DD/MM/YYYY');
      },
    },
  };
</script>

<style lang="scss" scoped>
  table {
    background-color: var(--white);
    border-spacing: 0;

    td {
      background-color: #f9f9f9;
      padding: 3px;
      font-size: 0.8em;
      color: var(--grey);
    }

    thead th:not(.empty),
    th {
      color: white;
      font-size: 0.8em;
      font-weight: bold;
      padding: 0.2rem 0.5rem;
      background-color: var(--primary-new);
    }

    thead td {
      padding: 4px;
      color: var(--white);
    }
  }

  .site-page-technical-data {
    text-align: center;

    &__first-column {
      width: 200px;
      text-align: left;
      padding: 0 0.5rem;
    }

    &__last-column {
      width: 70px;
    }
  }
</style>
