<template>
  <div v-if="profile.status === eJobStatus.Completed" class="site-page">
    <SitePageTitle />

    <SitePageSummary :profile="profile" />

    <SitePageTechnicalData
      class="mt-4"
      :analyse="currentTransport.cdpAnalyse"
      :temporalClasses="temporalClasses"
    />

    <SitePageTransportTable
      v-for="(transport, i) in profile.transports"
      :key="'transport' + i"
      class="mt-4"
      :transport="transport"
      :temporalClasses="temporalClasses"
    />

    <SitePageBudgetTable class="mt-4" :transports="profile.transports" />

    <div class="html2pdf__page-break"></div>
  </div>
</template>

<script>
  import posteHorosaisonnierOrder, {
    orderedTemporalClass,
  } from '../../../constants/posteHorosaisonnierOrder.constants';
  import {
    eAcheminementType,
    eJobStatus,
  } from '../../../services/TransportBudget.mapper';

  import SitePageTitle from './SitePageTitle';
  import SitePageSummary from './SitePageSummary';
  import SitePageTechnicalData from './SitePageTechnicalData';
  import SitePageTransportTable from './SitePageTransportTable';
  import SitePageBudgetTable from './SitePageBudgetTable';

  export default {
    name: 'SitePage',
    components: {
      SitePageTitle,
      SitePageSummary,
      SitePageTechnicalData,
      SitePageTransportTable,
      SitePageBudgetTable,
    },
    props: {
      profile: {
        type: Object,
        Required: true,
      },
    },
    data() {
      return {
        eJobStatus,
      };
    },
    computed: {
      temporalClasses() {
        let temporalClasses = [];
        if (
          this.profile.technicalData &&
          Object.keys(this.profile.technicalData.puissances).length >
            temporalClasses.length
        ) {
          orderedTemporalClass.forEach((ct) => {
            if (Object.keys(this.profile.technicalData.puissances).includes(ct))
              temporalClasses.push(ct);
          });
        }

        return [...new Set(temporalClasses)].sort(function (a, b) {
          const orderA = posteHorosaisonnierOrder[a] || 1000;
          const orderB = posteHorosaisonnierOrder[b] || 1001;
          return orderA - orderB;
        });
      },
      currentTransport() {
        return this.profile.transports.find(
          (t) => t.acheminementType === eAcheminementType.eCurrentAcheminement
        );
      },
    },
  };
</script>

<style lang="scss" scoped>
  .site-page {
    padding: 0 1rem;
  }
</style>
