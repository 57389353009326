<template>
  <div class="d-flex justify-space-between">
    <h1 class="ml-4 pb-4 site-page-title">Optimisation turpe 6</h1>
    <img class="mt-8 site-page-title__img" :src="logo" alt="" />
  </div>
</template>

<script>
  import logo from '../../../assets/img/logo.png';

  export default {
    name: 'SitePageTitle',
    data() {
      return { logo };
    },
  };
</script>

<style lang="scss" scoped>
  .site-page-title {
    color: var(--primary-new);
    font-size: 2em;
    text-align: left;
    align-self: end;

    &__img {
      width: 200px;
    }
  }
</style>
