<template>
  <div>
    <div class="d-flex justify-end">
      <img class="mt-2 logo" :src="logo" alt="" />
    </div>
    <div class="d-flex justify-center">
      <img class="mt-12 mb-12 cover" :src="cover" alt="" />
    </div>
  </div>
</template>

<script>
  import logo from '../../../assets/img/logo.png';
  import cover from '../../../assets/img/cover-pdf.png';

  export default {
    name: 'SynthesisCoverPageHeaderDetails',
    data() {
      return {
        logo,
        cover,
      };
    },
  };
</script>

<style lang="scss" scoped>
  @import 'src/client/style/constants.scss';

  .logo {
    width: 200px;
  }

  .cover {
    width: 100%;
  }
</style>
