<template>
  <div class="site-page-summary">
    <span class="mr-4">
      {{ profiles.length }} compteur{{ profiles.length > 1 ? 's' : '' }}
    </span>

    <div class="site-page-summary__pipe"></div>

    <span class="ml-4 mr-4">
      {{ endpointCategories }}
    </span>

    <div class="site-page-summary__pipe"></div>

    <span class="ml-4">
      du
      {{ formatDate(profiles[0].startDate) }} au
      {{ formatDate(profiles[0].endDate) }}
    </span>
  </div>
</template>

<script>
  import moment from 'moment';
  export default {
    name: 'SitePageSummary',
    props: {
      profiles: {
        type: Array,
        Required: true,
      },
    },
    computed: {
      endpointCategories() {
        const endpointCategories = this.profiles.map(
          (profile) => profile.technicalData.typeCompteur
        );

        return [...new Set(endpointCategories)].join(',');
      },
    },
    methods: {
      formatDate(dateTime) {
        return moment(dateTime).format('DD/MM/YYYY');
      },
    },
  };
</script>

<style lang="scss" scoped>
  .site-page-summary {
    display: flex;
    color: var(--white);
    background-color: var(--primary-new);
    padding: 0.5rem;
    font-size: 1.4em;

    &__pipe {
      width: 2px;
      background-color: var(--white);
    }
  }
</style>
