<template>
  <table>
    <SitePageOverviewHeader />
    <SitePageOverviewContent :profiles="profiles"></SitePageOverviewContent>
  </table>
</template>

<script>
  import SitePageOverviewHeader from './SitePageOverviewHeader';
  import SitePageOverviewContent from './SitePageOverviewContent';

  export default {
    name: 'SitePageOverviewTable',
    components: {
      SitePageOverviewHeader,
      SitePageOverviewContent,
    },
    props: {
      profiles: {
        type: Array,
        Required: true,
      },
    },
  };
</script>

<style lang="scss" scoped></style>
