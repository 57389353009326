<template>
  <td :style="{ whiteSpace: 'nowrap' }">
    <div class="benefits">
      <div
        v-for="(transport, i) in transportBenefits"
        :key="'transport' + i"
        class="mt-5 mb-5"
      >
        <div
          :class="[
            {
              current:
                transport.acheminementType ===
                eAcheminementType.eCurrentAcheminement,
            },
            transport.benefits < 0 ? 'lose' : 'gain',
            'd-flex justify-center',
          ]"
        >
          {{ showSign(transport.benefits) }}
          <AppNumber :value="transport.benefits" unit="€" />
        </div>
      </div>
    </div>
  </td>
</template>

<script>
  import { eAcheminementType } from '../../services/TransportBudget.mapper';
  import AppNumber from '../app/AppNumber';

  export default {
    name: 'OptimisationBenefits',
    components: {
      AppNumber,
    },
    props: {
      transports: {
        type: Array,
        Required: true,
      },
    },
    data() {
      return {
        eAcheminementType,
      };
    },
    computed: {
      transportBenefits() {
        return this.transports.map((transport) => {
          const currentBudgetAmount = this.transports.find(
            (e) => e.acheminementType === eAcheminementType.eCurrentAcheminement
          ).budget.montantHt;

          return {
            acheminementType: transport.acheminementType,
            benefits: currentBudgetAmount - transport.budget.montantHt,
          };
        });
      },
    },
    methods: {
      showSign(benefits) {
        return benefits < 0 ? '+' : '-';
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import 'src/client/style/constants.scss';
  .benefits {
    font-weight: bold;
  }

  .current {
    color: transparent !important;
  }

  .gain {
    color: var(--green-new);
  }
  .lose {
    color: var(--red-new);
  }
</style>
