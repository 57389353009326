<template>
  <v-container class="v-application synthesis-multisite-synopsis">
    <div v-if="profiles.length > 0">
      <SynthesisCoverPage :profiles="profiles" :isLandscape="true" />
      <SitePage class="d-flex flex-column" :profiles="profiles" />
    </div>
  </v-container>
</template>

<script>
  import SynthesisCoverPage from './cover-page/SynthesisCoverPage';
  import SitePage from './site-page-synopsis/SitePage';

  export default {
    name: 'SynthesisMultiSiteSynopsis',
    components: {
      SynthesisCoverPage,
      SitePage,
    },
    props: {
      profiles: {
        type: Array,
        Required: true,
      },
    },
  };
</script>

<style lang="scss" scoped>
  .synthesis-multisite-synopsis {
    padding: 0 !important;
  }
</style>
