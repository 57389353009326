<template>
  <table>
    <thead>
      <tr>
        <th
          :class="[
            cssAcheminementType(transport.acheminementType),
            'first-column',
          ]"
        >
          Acheminement {{ transport.acheminementType.toLowerCase() }}
        </th>
        <th
          v-for="(temporalClass, i) in temporalClasses"
          :key="'temporalClass' + i"
          :class="cssAcheminementType(transport.acheminementType)"
        >
          {{ temporalClass }}
        </th>
        <div :style="{ width: '5px' }"></div>
        <th
          :class="[
            cssAcheminementType(transport.acheminementType),
            'last-column',
          ]"
        >
          VERSION
        </th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td class="first-column">Puissance souscrite (kW)</td>
        <td v-for="(temporalClass, i) in temporalClasses" :key="'powers' + i">
          {{ transport.properties.puissances[temporalClass] }}
        </td>
        <div></div>
        <td>
          {{ transport.properties.versionUtilisation }}
        </td>
      </tr>
      <tr>
        <td class="first-column">Dépassement de puissance (kW)</td>
        <td
          v-for="(temporalClass, i) in temporalClasses"
          :key="'overuse-powers' + i"
        >
          <AppNumber
            :value="
              transport.cdpAnalyse.puissanceDepassementByTemporalClass[
                temporalClass
              ]
            "
          />
        </td>
      </tr>
      <tr>
        <td class="first-column">Dépassement (h)</td>
        <td
          v-for="(temporalClass, i) in temporalClasses"
          :key="'overuse-hours' + i"
        >
          <AppNumber
            :value="
              transport.cdpAnalyse.nbHoursDepassementByTemporalClass[
                temporalClass
              ]
            "
          />
        </td>
      </tr>
      <tr>
        <td class="first-column">Dépassement (€)</td>
        <td
          v-for="(temporalClass, i) in temporalClasses"
          :key="'overuse-amount' + i"
        >
          <AppNumber
            :value="
              transport.budget.composanteDepassement.amounts[temporalClass]
            "
          />
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
  import { eAcheminementType } from '../../../services/TransportBudget.mapper';
  import AppNumber from '../../app/AppNumber';

  export default {
    name: 'SitePageTransportTable',
    components: {
      AppNumber,
    },
    props: {
      transport: {
        type: Object,
        Required: true,
      },
      temporalClasses: {
        type: Array,
        required: true,
      },
    },
    methods: {
      cssAcheminementType(acheminementType) {
        if (acheminementType === eAcheminementType.eCurrentAcheminement)
          return 'current';
        if (acheminementType === eAcheminementType.eOptimizedAcheminement)
          return 'optimize';
        if (acheminementType === eAcheminementType.eAjustedAcheminement)
          return 'custom';
      },
    },
  };
</script>

<style lang="scss" scoped>
  table {
    background-color: var(--white);
    border-spacing: 0;

    td {
      background-color: #f9f9f9;
      padding: 3px;
      font-size: 0.8em;
      color: var(--grey);
      text-align: center;
      white-space: nowrap;
    }

    thead th:not(.empty),
    th {
      color: white;
      font-size: 0.8em;
      padding: 0.2rem 0.5rem;
    }

    thead td {
      padding: 4px;
      color: var(--white);
    }
  }

  .current {
    background-color: var(--secondary-grey);
  }

  .optimize {
    background-color: var(--secondary-new);
  }

  .custom {
    background-color: var(--secondary);
  }

  .first-column {
    width: 200px;
    text-align: left;
    padding: 0 0.5rem;
  }

  .last-column {
    width: 70px;
  }
</style>
