<template>
  <v-container class="v-application synthesis-multisite-itemize">
    <div v-if="profiles.length > 0" class="mt-2">
      <SynthesisCoverPage :profiles="profiles" :landscape="false" />
      <SitePage
        v-for="(profile, i) in profiles"
        :key="'multi-Synthesis' + i"
        class="d-flex flex-column"
        :profile="profile"
      />
    </div>
  </v-container>
</template>

<script>
  import SynthesisCoverPage from './cover-page/SynthesisCoverPage';
  import SitePage from './site-page-itemize/SitePage';

  export default {
    name: 'SynthesisMultiSiteItemize',
    components: {
      SynthesisCoverPage,
      SitePage,
    },
    props: {
      profiles: {
        type: Array,
        Required: true,
      },
    },
  };
</script>

<style lang="scss" scoped>
  .synthesis-multisite-itemize {
    padding: 0 !important;
  }
</style>
