<template>
  <td class="site-page-transport-type">
    <div
      v-for="(transport, i) in transports"
      :key="'transport' + i"
      class="mt-2 mb-2"
    >
      <div :class="cssAcheminementType(transport.acheminementType)">
        {{ transport.acheminementType }}
      </div>
    </div>
  </td>
</template>

<script>
  import { eAcheminementType } from '../../../services/TransportBudget.mapper';

  export default {
    name: 'SitePageTransportType',
    props: {
      transports: {
        type: Array,
        Required: true,
      },
    },
    methods: {
      cssAcheminementType(acheminementType) {
        if (acheminementType === eAcheminementType.eCurrentAcheminement)
          return 'current';
        if (acheminementType === eAcheminementType.eOptimizedAcheminement)
          return 'optimize';
        if (acheminementType === eAcheminementType.eAjustedAcheminement)
          return 'custom';
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import 'src/client/style/transport-type.scss';

  .site-page-transport-type {
    font-weight: bold;
    padding: 0 2px;
  }
</style>
