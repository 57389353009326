<template>
  <div class="d-flex">
    <div v-show="showedBtn">
      <v-btn
        v-if="!generalError"
        x-small
        plain
        @click.prevent="
          {
            downloadCsv();
          }
        "
      >
        <v-icon v-if="!generateCsvLoading"> mdi-download </v-icon>
        <v-progress-circular
          v-else
          indeterminate
          color="primary"
          loader-height="1"
          size="18"
          width="2"
          class="mr-2"
        />

        CSV
      </v-btn>
      <v-alert
        :style="{ marginBottom: 0, padding: ' 0.4rem 1rem 0' }"
        v-if="generalError"
        align="center"
        dense
        type="error"
      >
        <div class="title">Erreur</div>
      </v-alert>
    </div>
  </div>
</template>

<script>
  import { saveAs } from 'file-saver';
  import {
    eAcheminementType,
    eJobStatus,
  } from '../../services/TransportBudget.mapper';
  import {
    MatomoService,
    MATOMO_EVENTS,
  } from './../../services/MatomoApi.service';

  export default {
    name: 'OptimisationCsv',
    props: {
      data: {
        type: Array,
        Required: true,
      },
      showedBtn: {
        type: Boolean,
        Required: true,
      },
    },
    data() {
      return { generalError: false, generateCsvLoading: false };
    },
    methods: {
      async downloadCsv() {
        MatomoService.trackEvent(MATOMO_EVENTS.downloadCsvTransports);

        this.generateCsvLoading = true;

        try {
          const profiles = this.data
            .filter((e) => e.status == eJobStatus.Completed)
            .map((e) => ({
              ...e,
              actual: e.transports.find(
                (e) =>
                  e.acheminementType === eAcheminementType.eCurrentAcheminement
              ),
              optimize: e.transports.find(
                (e) =>
                  e.acheminementType ===
                  eAcheminementType.eOptimizedAcheminement
              ),
              ajusted: e.transports.find(
                (e) =>
                  e.acheminementType === eAcheminementType.eAjustedAcheminement
              ),
              ajustmentMargin: e.transports.find(
                (e) =>
                  e.acheminementType === eAcheminementType.eAjustedAcheminement
              ).ajustmentMargin,
            }));
          const response = await this.$http.post(
            '/acheminement/downloadAcheminementCsv',
            {
              profiles,
            },
            { withCredentials: true }
          );
          const filename = `Optimisation tupe.csv`;
          const BOM = '\uFEFF';
          const csvData = BOM + response.data;
          const blob = new Blob([csvData], {
            type: 'text/csv;charset=utf-8',
          });
          saveAs(blob, filename);
        } catch (err) {
          if (err.response?.data?.message) {
            this.generalError = true;
            console.error(err.response.data.message);
          } else {
            this.generalError = true;
            console.error(err.message);
          }
        } finally {
          this.generateCsvLoading = false;
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import 'src/client/style/table.scss';
</style>
