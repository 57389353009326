<template>
  <td>
    <div>
      {{ prm }}
    </div>
    <v-btn
      v-if="status === eJobStatus.Completed"
      class="pl-8 pr-8 download-button"
      small
      color="var(--secondary)"
      @click.prevent="
        {
          (generatePdfLoading = true), $emit('setProfileForPdf');
        }
      "
    >
      <v-icon v-if="!generatePdfLoading"> mdi-download </v-icon>
      <v-progress-circular
        v-else
        indeterminate
        loader-height="1"
        size="18"
        width="2"
        class="mr-2"
      />

      PDF
    </v-btn>
  </td>
</template>

<script>
  import { eJobStatus } from '../../services/TransportBudget.mapper';

  export default {
    name: 'OptimisationIdentification',
    props: {
      prm: {
        type: String,
        Required: true,
      },
      status: {
        type: String,
        Required: true,
      },
      togglePdfLoading: {
        type: Boolean,
        Required: true,
      },
    },
    data() {
      return {
        eJobStatus,
        generatePdfLoading: false,
      };
    },
    watch: {
      togglePdfLoading() {
        this.generatePdfLoading = false;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .download-button {
    color: white;
    width: 80%;
  }
</style>
