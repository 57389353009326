<template>
  <div class="mb-6 d-flex justify-space-between">
    <div class="d-flex cover-container">
      <img class="img" :src="cover" alt="" />
    </div>
    <div class="logo-container">
      <img class="img" :src="logo" alt="" />
    </div>
  </div>
</template>

<script>
  import logo from '../../../assets/img/logo.png';
  import cover from '../../../assets/img/offre-energie.png';

  export default {
    name: 'SynthesisCoverPageHeaderSummary',
    data() {
      return {
        logo,
        cover,
      };
    },
  };
</script>

<style lang="scss" scoped>
  @import 'src/client/style/constants.scss';

  .logo-container {
    width: 20%;
  }

  .cover-container {
    width: 70%;
  }

  .img {
    width: 100%;
  }
</style>
