<template>
  <tr>
    <td colspan="2" class="pt-2">
      <div class="primary-color common">Vous pouvez économiser :</div>
    </td>

    <td
      v-for="(transport, i) in transportBenefits"
      :key="'datas' + i"
      class="pt-2 pl-4"
    >
      <div :class="['common', cssAcheminementType(transport.acheminementType)]">
        <AppNumber :value="transport.benefit" :showSign="true" unit="€" />
      </div>
    </td>
  </tr>
</template>

<script>
  import { eAcheminementType } from '../../../../server/modules/acheminement/acheminement.interface';
  import AppNumber from '../../app/AppNumber';

  export default {
    name: 'SitePageBudgetFooter',
    components: {
      AppNumber,
    },
    props: {
      transports: {
        type: Array,
        required: true,
      },
    },
    computed: {
      transportBenefits() {
        return this.transports
          .filter(
            (transport) =>
              transport.acheminementType !==
              eAcheminementType.eCurrentAcheminement
          )
          .map((transport) => ({
            acheminementType: transport.acheminementType,
            benefit: this.computeBenefit(transport.budget.montantHt),
          }));
      },
    },
    methods: {
      cssAcheminementType(acheminementType) {
        if (acheminementType === eAcheminementType.eCurrentAcheminement)
          return 'current';
        if (acheminementType === eAcheminementType.eOptimizedAcheminement)
          return 'optimize';
        if (acheminementType === eAcheminementType.eAjustedAcheminement)
          return 'custom';
      },
      computeBenefit(transportAmount) {
        const currentBudgetAmount = this.transports.find(
          (e) => e.acheminementType === eAcheminementType.eCurrentAcheminement
        ).budget.montantHt;

        return currentBudgetAmount - transportAmount;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .common {
    font-weight: bold;
    text-align: center;
    color: var(--white);
    padding: 0.2rem;
  }

  .primary-color {
    background-color: var(--primary-new);
  }

  .optimize {
    background-color: var(--secondary-new);
  }

  .custom {
    background-color: var(--secondary);
  }
</style>
