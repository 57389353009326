<template>
  <div class="d-flex justify-space-between">
    <h1 class="pb-4 title">Récapitulatif optimisation turpe multisite</h1>
    <img class="mt-8 img" :src="logo" alt="" />
  </div>
</template>

<script>
  import logo from '../../../assets/img/logo.png';

  export default {
    name: 'SitePageTitle',
    data() {
      return { logo };
    },
  };
</script>

<style lang="scss" scoped>
  .title {
    color: var(--primary-new);
    font-size: 2rem !important;
    text-align: left;
    align-self: end;
    font-weight: bold;
    margin-left: 2px;
  }

  .img {
    width: 200px;
  }
</style>
