var render = function render(){var _vm=this,_c=_vm._self._c;return _c('td',{style:({ whiteSpace: 'nowrap' })},[_c('div',{staticClass:"benefits"},_vm._l((_vm.transportBenefits),function(transport,i){return _c('div',{key:'transport' + i,staticClass:"mt-5 mb-5"},[_c('div',{class:[
          {
            current:
              transport.acheminementType ===
              _vm.eAcheminementType.eCurrentAcheminement,
          },
          transport.benefits < 0 ? 'lose' : 'gain',
          'd-flex justify-center',
        ]},[_vm._v(" "+_vm._s(_vm.showSign(transport.benefits))+" "),_c('AppNumber',{attrs:{"value":transport.benefits,"unit":"€"}})],1)])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }