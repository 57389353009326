<template>
  <thead>
    <tr class="headers">
      <th class="header" rowspan="2"></th>
      <th class="header" rowspan="2">Type de compteur</th>
      <th class="header" rowspan="2">Volume total MWh</th>
      <th class="header" rowspan="2"></th>
      <th class="header" v-bind:colspan="lengthTemporalClasses">
        Puissances (Kw)
      </th>
      <th class="header" rowspan="2">Version d'utilisation</th>
      <th class="header" rowspan="2">Gestion + Comptage</th>
      <th class="header" rowspan="2">Part Fixe</th>
      <th class="header" rowspan="2">Part Variable</th>
      <th class="header" rowspan="2">Taxes</th>
      <th class="header" rowspan="2">Coût de modification</th>
      <th class="header" rowspan="2">Total</th>
      <th class="header" rowspan="2">Gain</th>
    </tr>
    <tr>
      <th
        v-for="(temporalClass, i) of temporalClasses"
        :key="'temporalClass-power' + i"
      >
        <div class="power">{{ temporalClass }}</div>
      </th>
    </tr>
  </thead>
</template>

<script>
  export default {
    name: 'SitePageDetailsHeader',
    props: {
      temporalClasses: {
        type: Array,
        Required: true,
      },
      lengthTemporalClasses: {
        type: Number,
        Required: true,
      },
    },
  };
</script>

<style lang="scss" scoped>
  .headers {
    color: var(--primary-new);
    white-space: 'nowrap';
    font-size: 0.7em;
  }

  .header {
    padding: 0 1px;
    font-weight: 400;
  }

  .header:last-child {
    font-weight: bold;
  }

  .power {
    color: var(--white);
    background-color: var(--primary-new);
    font-size: 0.9em;
    padding: 0 2px;
  }
</style>
