<template>
  <tbody>
    <tr>
      <td class="font-weight-bold">
        {{ profiles.length }} compteur{{ profiles.length > 1 ? 's' : '' }}
      </td>
      <td class="font-weight-bold">
        <AppNumber :value="totalConsulmptions" unit="MWh" />
      </td>

      <SitePageTransportType
        :transportTypes="transportTypes"
        :transports="transports"
      />

      <SitePageOverviewDetail
        amountType="accoutingAndAdministration"
        :transports="transports"
      />

      <SitePageOverviewDetail
        amountType="infrastructuralPower"
        :transports="transports"
      />

      <SitePageOverviewDetail
        amountType="infrastructuralEnergy"
        :transports="transports"
      />

      <SitePageOverviewDetail amountType="tax" :transports="transports" />

      <SitePageOverviewDetail
        amountType="endpointServiceCostBudget"
        :transports="transports"
      />

      <SitePageOverviewDetail amountType="total" :transports="transports" />

      <SitePageOverviewDetail
        amountType="benefit"
        :transports="transportBenefits"
        :showSign="true"
      />
    </tr>
  </tbody>
</template>

<script>
  import { eAcheminementType } from '../../../../services/TransportBudget.mapper';
  import AppNumber from '../../../app/AppNumber';
  import SitePageTransportType from '../SitePageTransportType';
  import SitePageOverviewDetail from './SitePageOverviewDetail';

  export default {
    name: 'SitePageOverviewContent',
    components: {
      AppNumber,
      SitePageTransportType,
      SitePageOverviewDetail,
    },
    props: {
      profiles: {
        type: Array,
        Required: true,
      },
    },
    computed: {
      totalConsulmptions() {
        const consumptions = this.profiles.map((profile) => ({
          totalConsumption: profile.transports[0].cdpAnalyse.consommationTotale,
        }));

        return consumptions.reduce(
          (total, profile) => total + profile.totalConsumption,
          0
        );
      },
      transportTypes() {
        const transportTypes = [
          ...new Set(
            this.profiles.flatMap((profile) =>
              profile.transports.map((transport) => transport.acheminementType)
            )
          ),
        ];

        return transportTypes;
      },
      transports() {
        return this.transportTypes.map((type) => {
          const transports = this.profiles.flatMap((profile) =>
            profile.transports.filter(
              (transport) => transport.acheminementType === type
            )
          );

          const accoutingAndAdministrations = transports.map(
            (t) =>
              t.budget.composanteComptage.amountExcludingVat +
              t.budget.composanteGestion.amountExcludingVat
          );
          const infrastructuralPowers = transports.map(
            (t) => t.budget.composanteSoutirage.power.total
          );
          const infrastructuralEnergy = transports.map(
            (t) => t.budget.composanteSoutirage.energy.total
          );
          const taxes = transports.map(
            (t) => t.budget.taxes.contributionTarifaireAcheminement.montantHt
          );
          const endpointServiceCostBudget = transports.map(
            (t) => t.budget.endpointServiceCostBudget.amountExcludingVat
          );
          const totals = transports.map((t) => t.budget.montantHt);

          return {
            acheminementType: type,
            accoutingAndAdministration: this.reduceValues(
              accoutingAndAdministrations
            ),
            infrastructuralPower: this.reduceValues(infrastructuralPowers),
            infrastructuralEnergy: this.reduceValues(infrastructuralEnergy),
            tax: this.reduceValues(taxes),
            endpointServiceCostBudget: this.reduceValues(
              endpointServiceCostBudget
            ),
            total: this.reduceValues(totals),
          };
        });
      },
      transportBenefits() {
        return this.transports.map((transport) => ({
          acheminementType: transport.acheminementType,
          benefit: this.computeBenefit(transport.total),
        }));
      },
    },
    methods: {
      reduceValues(values) {
        return values.reduce((total, value) => total + value, 0);
      },
      computeBenefit(transportAmount) {
        const currentBudgetAmount = this.transports.find(
          (e) => e.acheminementType === eAcheminementType.eCurrentAcheminement
        ).total;

        return currentBudgetAmount - transportAmount;
      },
    },
  };
</script>

<style lang="scss" scoped>
  tr {
    border: 2px solid var(--primary-new);
  }

  td {
    font-size: 0.8em;
    text-align: center;
    color: var(--grey);
  }
</style>
