import { render, staticRenderFns } from "./OptimisationTransportType.vue?vue&type=template&id=452a7964&scoped=true"
import script from "./OptimisationTransportType.vue?vue&type=script&lang=js"
export * from "./OptimisationTransportType.vue?vue&type=script&lang=js"
import style0 from "./OptimisationTransportType.vue?vue&type=style&index=0&id=452a7964&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "452a7964",
  null
  
)

export default component.exports