<template>
  <div :style="{ backgroundColor: 'var(--secondary-grey-new) !important' }">
    <v-card
      class="pa-6"
      v-if="!computeTransportLoading && !generalError"
      outlined
    >
      <v-row class="justify-space-between flex-wrap">
        <div :style="{ width: '35%', marginTop: '0.5rem' }">
          <v-row>
            <v-col>
              <v-menu
                v-model="startDateMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="startDateInput"
                    :height="25"
                    :style="{ fontSize: '0.8em' }"
                    :error-messages="startDateErrors"
                    :error="!startDateBeforeEndDate"
                    label="Date de début (incluse)"
                    prepend-icon="mdi-calendar"
                    hide-details
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="startDateInput"
                  no-title
                  :min="minDate"
                  :max="maxDate"
                  locale="fr-FR"
                  @input="
                    () => {
                      $v.startDate.$touch();
                      startDateMenu = false;
                    }
                  "
                  @blur="$v.startDate.$touch()"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col>
              <v-menu
                v-model="endDateMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="endDateInput"
                    :height="25"
                    :style="{ fontSize: '0.8em' }"
                    :error-messages="endDateErrors"
                    :error="!startDateBeforeEndDate"
                    label="Date de fin (incluse)"
                    prepend-icon="mdi-calendar"
                    hide-details
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="endDateInput"
                  no-title
                  :min="minDate"
                  :max="maxDate"
                  locale="fr-FR"
                  @input="
                    () => {
                      $v.endDate.$touch();
                      endDateMenu = false;
                    }
                  "
                  @blur="$v.endDate.$touch()"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <div>
            <div
              v-if="!startDateBeforeEndDate"
              align="center"
              :style="{
                margin: 0,
                padding: '0.1rem 0',
                fontSize: '0.6em',
                color: 'var(--red-new)',
              }"
            >
              La date de fin doit être postérieure à la date de début.
            </div>
            <div
              v-if="!periodIsYear"
              align="center"
              :style="{
                margin: 0,
                padding: '0.1rem 0',
                fontSize: '0.6em',
                color: '#d06802',
              }"
            >
              Attention, la période renseignée ne fait pas exactement une année.
            </div>
          </div>

          <DateSelectionShortcut
            :startYearsSubstract="1"
            :nbTotalButton="4"
            @selectShortcutYear="selectShortcutYear"
          />
        </div>

        <div
          class="d-flex justify-space-between align-center"
          :style="{ width: '65%', marginTop: '0.5rem' }"
        >
          <AppJobState class="mb-4 ml-2" :jobLeft="jobLeft" />

          <div class="d-flex mr-2">
            <div class="d-flex mr-4">
              <p :style="{ whiteSpace: 'nowrap', fontSize: '0.8em' }">
                Marge acheminement
              </p>
              <div class="d-flex">
                <div class="d-flex ml-2">
                  <v-text-field
                    v-model.number="marge"
                    :height="25"
                    :style="{
                      fontSize: '0.8em',
                      width: '4rem',
                      padding: '0',
                      margin: '0',
                    }"
                    type="number"
                    suffix="%"
                    placeholder="marge %"
                    required
                    hide-details
                    :error-messages="margeErrors"
                  ></v-text-field>
                  <v-btn
                    v-if="showedBtn"
                    small
                    plain
                    @click.prevent="refreshAllCustomTransport()"
                  >
                    <v-icon>mdi-refresh</v-icon>
                  </v-btn>
                </div>
              </div>
            </div>

            <div class="d-flex">
              <p :style="{ whiteSpace: 'nowrap', fontSize: '0.8em' }">
                Version TURPE
              </p>
              <div class="d-flex">
                <div class="d-flex ml-2">
                  <v-select
                    v-model="turpeVersion"
                    :height="25"
                    :style="{
                      fontSize: '0.8em',
                      width: '4rem',
                      padding: '0',
                      margin: '0',
                    }"
                    :items="availableTurpeVersion"
                    required
                    hide-details
                  ></v-select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-row>

      <div class="d-flex mt-8 ml-5 flex-wrap justify-space-between">
        <div class="d-flex">
          <div>
            <v-btn
              small
              color="primary"
              block
              :loading="fetchTransportLoading"
              @click.prevent="fetchTransports()"
            >
              Lancer la consultation
            </v-btn>
          </div>

          <div v-show="hasTransports" class="ml-2">
            <v-btn
              small
              color="primary"
              block
              :loading="computeTransportLoading"
              @click.prevent="computeAllTransports()"
            >
              Calculer les acheminements
            </v-btn>
          </div>
        </div>
        <div class="d-flex">
          <OptimisationSynthesisDownloader
            v-show="showedBtn"
            title="PDF"
            icon="mdi-file-pdf-box"
            :profiles="profileList"
            :generatePdfLoading="generatePdfLoading"
            @downloadSynthesisDetails="generateReport('details')"
            @downloadSynthesisSummary="generateReport('summary')"
          />

          <OptimisationCsv :showedBtn="showedBtn" :data="profileList" />

          <div>
            <v-btn
              x-small
              plain
              @click="infos = true"
              :style="{
                color: '#0077ba',
              }"
            >
              <div
                :style="{
                  whiteSpace: 'nowrap',
                  display: 'flex',
                  fontSize: '0.8em',
                }"
              >
                <v-icon :style="{ color: 'var(--secondary)' }">
                  mdi-information-outline
                </v-icon>
                <span class="ml-1 align-self-center">INFOS</span>
              </div>
            </v-btn>
          </div>
        </div>
      </div>
    </v-card>

    <v-card
      class="pt-2 pb-3"
      v-if="computeTransportLoading || generalError"
      outlined
    >
      <v-row align="center">
        <v-col md="12">
          <OptimisationLoader
            v-if="computeTransportLoading"
            class="text-center"
            :profiles="profileList"
            :numberOfJobsComplete="numberOfJobsComplete"
          />

          <v-alert
            v-if="generalError"
            class="mb-0"
            align="center"
            dense
            outlined
            type="error"
          >
            <div class="title">Erreur général</div>
            <pre :style="{ overflow: 'hidden' }">{{
              JSON.stringify(generalError, null, '\t')
            }}</pre>
          </v-alert>
        </v-col>
      </v-row>
    </v-card>

    <div
      v-if="infos && !generalError"
      class="mt-2"
      :style="{
        border: 'thin solid #0077ba',
        borderRadius: '5px',
        backgroundColor: 'var(--white)',
      }"
    >
      <v-col
        v-model="infos"
        close-text="Close infos"
        :style="{
          display: 'flex',
          justifyContent: 'space-between',
        }"
      >
        <div
          :style="{
            display: 'flex',
            fontSize: '0.8em',
          }"
        >
          <v-icon :style="{ color: 'var(--secondary)', alignSelf: 'start' }">
            mdi-information-outline
          </v-icon>
          <section :style="{ color: 'var(--secondary)' }" class="ml-4">
            <p :style="{ marginBottom: '10px' }">
              Les calculs d’acheminement se basent sur les données du PRM
              renseignées dans Salesforce.
            </p>
            <p :style="{ marginBottom: '10px' }">
              Ne pas relancer les demandes d’optimisation si elles sont déjà en
              cours.
            </p>
            <p :style="{ marginBottom: '10px' }">Cas d’erreurs spécifiques :</p>
            <ul>
              <li>
                FTA ou puissances manquantes → Compléter les données dans
                Salesforce en amont, fonctionnalité disponible dans l’onglet DT&
                CONSO
              </li>
              <li>
                Courbe de puissance incomplète → Lancer une consultation de
                Courbe de Puissance en amont
              </li>
            </ul>
          </section>
        </div>
        <button :style="{ alignSelf: 'start' }" @click="infos = false">
          <v-icon :style="{ color: '#0077ba' }"> mdi-close-circle </v-icon>
        </button>
      </v-col>
    </div>

    <p
      v-if="hasTransports && !generalError"
      class="mt-2 mb-2 ml-1"
      :style="{ fontSize: '0.8em' }"
    >
      CALCULS D'ACHEMINEMENT DU {{ finalStartDate }} AU {{ finalEndDate }} -
      VERSION TURPE {{ turpeVersion }}
    </p>

    <v-card v-if="hasTransports" outlined>
      <v-row align="center">
        <v-col md="12">
          <OptimisationTable
            v-if="!generalError"
            class="text-center"
            :prms="prms"
            :profiles="profileList"
            :isComputeCustomTransport="isComputeCustomTransport"
            @computeCustomTransport="computeCustomTransport"
          />
        </v-col>
      </v-row>
    </v-card>

    <div :style="{ display: 'none' }">
      <vue-html2pdf
        :show-layout="false"
        :float-layout="false"
        :enable-download="true"
        :preview-modal="true"
        :manual-pagination="true"
        filename="OPTIMISATION TURPE 6 MULTISITE"
        :pdf-quality="2"
        pdf-format="a4"
        pdf-orientation="portrait"
        pdf-content-width="795px"
        @hasDownloaded="hasDownloaded"
        ref="synthesisDetail"
      >
        <SynthesisMultiSiteItemize
          slot="pdf-content"
          :profiles="filterCompletedProfiles"
        />
      </vue-html2pdf>

      <vue-html2pdf
        :show-layout="false"
        :float-layout="false"
        :enable-download="true"
        :preview-modal="true"
        :manual-pagination="true"
        filename="OPTIMISATION TURPE 6 MULTISITE"
        :pdf-quality="2"
        pdf-format="a4"
        pdf-orientation="landscape"
        pdf-content-width="1125px"
        @hasDownloaded="hasDownloaded"
        ref="synthesisSummary"
      >
        <SynthesisMultiSiteSynopsis
          slot="pdf-content"
          :profiles="filterCompletedProfiles"
        />
      </vue-html2pdf>
    </div>
  </div>
</template>

<script>
  import moment from 'moment-timezone';
  import socket from '../../socket';
  import { mapState } from 'vuex';
  import { required } from 'vuelidate/lib/validators';
  import {
    minPrmLengthArray,
    maxPrmLengthArray,
    formatPrmArray,
    isStartDateBeforeEndDate,
  } from '../../validators/enedisValidators';
  import VueHtml2pdf from 'vue-html2pdf';

  import OptimisationLoader from './OptimisationLoader';
  import OptimisationTable from './OptimisationTable';
  import SynthesisMultiSiteItemize from '../synthesis/SynthesisMultiSiteItemize';
  import SynthesisMultiSiteSynopsis from '../synthesis/SynthesisMultiSiteSynopsis';
  import OptimisationCsv from './OptimisationCsv';
  import OptimisationSynthesisDownloader from './OptimisationSynthesisDownloader';
  import DateSelectionShortcut from '../app/AppDateSelectionShortcut';
  import AppJobState from '../app/AppJobState';
  import { PowerTransportPropertyType } from '../../services/business-data/dto/power-transport.interface';
  import businessDataApiService from '../../services/business-data/business-data.service';
  import transportBudgetMapper, {
    eJobStatus,
  } from '../../services/TransportBudget.mapper';
  import { EndpointCommunicationCapability } from '@Collectif-Energie/collective-lib';
  import { eAcheminementType } from '../../services/TransportBudget.mapper';
  import enedisApiClient from '../../services/enedis-api/enedis-api.client';

  import {
    MatomoService,
    MATOMO_EVENTS,
  } from './../../services/MatomoApi.service';
  export default {
    name: 'OptimisationTurpeTabs',
    components: {
      OptimisationLoader,
      OptimisationTable,
      VueHtml2pdf,
      SynthesisMultiSiteItemize,
      SynthesisMultiSiteSynopsis,
      OptimisationCsv,
      DateSelectionShortcut,
      OptimisationSynthesisDownloader,
      AppJobState,
    },
    props: {
      prms: {
        type: Array,
      },
      validation: {
        type: Function,
      },
      startDate: {
        type: String,
      },
      endDate: {
        type: String,
      },
      startDateBeforeEndDate: {
        type: Boolean,
      },
      periodIsYear: {
        type: Boolean,
      },
    },
    data() {
      return {
        infos: true,
        marge: 10,
        showedBtn: false,
        numberOfJobsComplete: 0,
        startDateInput: this.startDate,
        endDateInput: this.endDate,
        finalStartDate: '',
        finalEndDate: '',
        generalError: null,
        errors: [],
        startDateMenu: false,
        endDateMenu: false,
        maxDate: moment()
          .add(10, 'years')
          .tz('Europe/Paris')
          .format('YYYY-MM-DD'),
        minDate: moment()
          .subtract(10, 'years')
          .tz('Europe/Paris')
          .format('YYYY-MM-DD'),
        profileList: [],
        fetchTransportLoading: false,
        computeTransportLoading: false,
        generatePdfLoading: false,
        hasTransports: false,
        turpeVersion: '2023',
        availableTurpeVersion: ['2023'],
        isComputeCustomTransport: false,
      };
    },
    computed: {
      ...mapState('auth', ['user']),
      ...mapState('transports', ['jobLeft']),
      startDateErrors() {
        const errors = [];
        if (!this.$v.startDate.$dirty) return errors;
        !this.$v.startDate.required &&
          errors.push('Veuillez renseigner une date de début');
        return errors;
      },
      endDateErrors() {
        const errors = [];
        if (!this.$v.endDate.$dirty) return errors;
        !this.$v.endDate.required &&
          errors.push('Veuillez renseigner une date de fin');
        return errors;
      },
      margeErrors() {
        const errors = [];
        if (!this.$v.marge.$dirty) return errors;
        !this.$v.marge.required && errors.push('Veuillez renseigner une marge');
        return errors;
      },
      filterCompletedProfiles() {
        return this.profileList.filter(
          (e) => e.status === eJobStatus.Completed
        );
      },
      marginCoefficient() {
        return this.marge ? 1 + this.marge / 100 : 1;
      },
    },
    created() {
      socket.on('transport:budget:result', (response) => {
        if (response.status === eJobStatus.Completed) {
          if (!response.result)
            return this.createFailedProfile(
              response.prm,
              response.errorMessage
            );

          const transport = transportBudgetMapper.buildTransport(
            response.result
          );

          this.addTransport(response.prm, transport);
          this.checkAndComputeCustomTransport(response.prm, transport);
        }

        if (response.status === eJobStatus.Failed)
          return this.createFailedProfile(response.prm, response.errorMessage);

        this.isComputeCustomTransport = false;
      });
      socket.on('transport:optimization:result', (response) => {
        if (response.status === eJobStatus.Completed) {
          if (!response.result)
            return this.createFailedProfile(
              response.prm,
              response.errorMessage
            );

          const transport = transportBudgetMapper.buildTransport(
            response.result
          );

          this.addTransport(response.prm, transport);
          this.checkAndComputeCustomTransport(response.prm, transport);
        }

        if (response.status === eJobStatus.Failed)
          return this.createFailedProfile(response.prm, response.errorMessage);
      });
    },
    mounted() {
      MatomoService.trackPageView('Optimisation TURPE');
    },
    watch: {
      profileList(profileList) {
        const countPrmJobDone = profileList.filter(
          (e) =>
            e.status === eJobStatus.Completed || e.status === eJobStatus.Failed
        );
        this.numberOfJobsComplete = countPrmJobDone.length;

        if (countPrmJobDone.length === profileList.length) {
          this.computeTransportLoading = false;
        }

        const isPrmCompleted = profileList.filter((profile) => {
          const acheminementTypes = profile.transports?.map(
            (t) => t.acheminementType
          );

          return (
            acheminementTypes?.toString() ===
            [
              eAcheminementType.eCurrentAcheminement,
              eAcheminementType.eOptimizedAcheminement,
              eAcheminementType.eAjustedAcheminement,
            ].toString()
          );
        });

        isPrmCompleted.length === 0
          ? (this.showedBtn = false)
          : (this.showedBtn = true);
      },
      startDate() {
        this.startDateInput = this.startDate;
      },
      endDate() {
        this.endDateInput = this.endDate;
      },
      startDateInput() {
        this.$emit('startDateModifier', this.startDateInput);
      },
      endDateInput() {
        this.$emit('endDateModifier', this.endDateInput);
      },
    },
    validations: {
      prms: {
        required,
        minPrmLengthArray,
        maxPrmLengthArray,
        formatPrmArray,
      },
      startDate: { required, isStartDateBeforeEndDate },
      endDate: { required },
      marge: { required },
    },
    methods: {
      async fetchTransports() {
        this.$v.$touch();
        this.validation();

        if (!this.$v.$invalid) {
          MatomoService.trackEvent(
            MATOMO_EVENTS.consultTransports,
            this.prms.length
          );

          this.resetForm();

          const startDate = moment(this.startDate);
          const endDate = moment(this.endDate);

          this.finalStartDate = startDate.format('DD/MM/YYYY');
          this.finalEndDate = endDate.format('DD/MM/YYYY');

          this.fetchTransportLoading = true;
          this.hasTransports = true;

          for (const prm of [...new Set(this.prms)]) {
            this.profileList.push({
              prm,
              status: eJobStatus.Active,
              transports: [],
            });
          }

          const params = {
            prms: [...new Set(this.prms)],
            margin: this.marge ? 1 + this.marge / 100 : 1,
            startDate: startDate.toDate(),
            endDate: endDate.toDate(),
          };

          try {
            const data = await businessDataApiService.fetchTransports(params);

            this.profileList.forEach((profile, i) => {
              const profileIndex = data.findIndex((p) => {
                return p?.prm === profile.prm;
              });

              if (profileIndex === -1)
                return this.createFailedProfile(
                  profile.prm,
                  "Aucun calcul d'acheminement enregistré pour ce PRM, veuillez lancer un calcul d'acheminement"
                );

              data[profileIndex].status = this.updateProfileStatus(
                data[profileIndex]
              );
              return this.profileList.splice(i, 1, data[profileIndex]);
            });
          } catch (err) {
            this.generalError = err.message;
          } finally {
            this.fetchTransportLoading = false;
          }
        }
      },
      async computeAllTransports() {
        this.$v.$touch();
        this.validation();
        if (!this.$v.$invalid) {
          MatomoService.trackEvent(
            MATOMO_EVENTS.computeTransports,
            this.prms.length
          );

          this.computeTransportLoading = true;
          this.numberOfJobsComplete = 0;

          const startDate = moment(this.startDate);
          const endDate = moment(this.endDate);

          this.finalStartDate = startDate.format('DD/MM/YYYY');
          this.finalEndDate = endDate.format('DD/MM/YYYY');

          const inputPrms = [...new Set(this.prms)];
          const currentPrms = this.profileList.map((e) => e.prm);

          inputPrms.forEach((prm) => {
            if (currentPrms.includes(prm)) {
              const profile = this.profileList.find((p) => p.prm === prm);
              (profile.status = eJobStatus.Active), (profile.transports = []);
            } else {
              this.profileList.push({
                prm,
                status: eJobStatus.Active,
                transports: [],
              });
            }
          });

          const prms = this.profileList.map((e) => e.prm);
          prms.forEach(async (prm) => {
            await this.computeTransports({
              prm,
              margin: this.marge ? 1 + this.marge / 100 : 1,
              startDate: startDate.toDate(),
              endDate: endDate.toDate(),
            });
          });
        }
      },
      async computeTransports(parameters) {
        try {
          const transportProfile =
            await businessDataApiService.computeTransports(parameters);

          this.updateTechnicalData(transportProfile);
        } catch (err) {
          this.createFailedProfile(parameters.prm, err.message);
        }
      },
      updateTechnicalData(transportProfile) {
        const profileIndex = this.profileList.findIndex((p) => {
          return p.prm === transportProfile.prm;
        });
        let profile = this.profileList[profileIndex];

        profile = { ...profile, ...transportProfile };
        profile.status = this.updateProfileStatus(profile);

        this.profileList.splice(profileIndex, 1, profile);
      },
      async refreshAllCustomTransport() {
        this.$v.$touch();
        this.validation();

        if (!this.$v.$invalid) {
          MatomoService.trackEvent(
            MATOMO_EVENTS.refreshCustomTransports,
            this.marge
          );

          this.isComputeCustomTransport = true;
          this.numberOfJobsComplete = 0;
          this.showedBtn = false;

          await Promise.all(
            this.profileList
              .filter((p) => p.status === eJobStatus.Completed)
              .map(async (p) => {
                const optimiseTransport = JSON.parse(
                  JSON.stringify(
                    p.transports.find(
                      (t) =>
                        t.acheminementType ===
                        eAcheminementType.eOptimizedAcheminement
                    )
                  )
                );
                if (!optimiseTransport) return;

                const perimeter = {
                  prm: p.prm,
                  startDate: p.startDate,
                  endDate: p.endDate,
                };
                const properties = JSON.parse(
                  JSON.stringify(optimiseTransport.properties)
                );
                properties.puissances = this.increasePowerThroughMargin(
                  properties.puissances
                );

                await this.computeCustomTransport(perimeter, properties);
              })
          );
        }
      },
      increasePowerThroughMargin(powers) {
        const subscribedPowers = powers;

        if (this.marginCoefficient !== 1)
          for (const temporalClass in powers) {
            if (!subscribedPowers[temporalClass]) continue;

            subscribedPowers[temporalClass] = Math.round(
              subscribedPowers[temporalClass] * this.marginCoefficient
            );
          }

        return subscribedPowers;
      },
      checkAndComputeCustomTransport(prm, transport) {
        if (
          transport.acheminementType === eAcheminementType.eAjustedAcheminement
        )
          return;

        const profile = this.profileList.find((profile) => profile.prm === prm);

        const acheminementTypes = profile.transports.map(
          (t) => t.acheminementType
        );
        if (
          !acheminementTypes.includes(eAcheminementType.eCurrentAcheminement) ||
          !acheminementTypes.includes(eAcheminementType.eOptimizedAcheminement)
        )
          return;

        const optimizeTransport = profile.transports.find(
          (transport) =>
            transport.acheminementType ===
            eAcheminementType.eOptimizedAcheminement
        );

        const perimeter = {
          prm,
          startDate: optimizeTransport.cdpAnalyse.analysedPeriodStartDate,
          endDate: optimizeTransport.cdpAnalyse.analysedPeriodEndDate,
        };
        const properties = JSON.parse(
          JSON.stringify(optimizeTransport.properties)
        );
        properties.puissances = this.increasePowerThroughMargin(
          properties.puissances
        );

        this.computeCustomTransport(perimeter, properties);
      },
      async computeCustomTransport(
        perimeter,
        properties,
        marginCoefficient = this.marginCoefficient
      ) {
        try {
          const transportPerimeter = {
            prm: perimeter.prm,
            startDate: perimeter.startDate,
            endDate: perimeter.endDate,
            marginCoefficient,
            type: PowerTransportPropertyType.Custom,
          };

          const simulationTransportProperties = {
            transportCode: properties.fta,
            subscribedPowers: properties.puissances,
            endpointCategory: properties.typeCompteur,
            consumptionProfile: properties.versionUtilisation,
            endpointConnection: properties.typeRaccordement,
            endpointCommunicationCapability:
              properties.endpointCommunicationCapability ??
              EndpointCommunicationCapability.Local,
          };

          const profile = this.profileList.find(
            (profile) => profile.prm === perimeter.prm
          );
          const currentTransport = profile.transports.find(
            (transport) =>
              transport.acheminementType ===
              eAcheminementType.eCurrentAcheminement
          );

          const currentTransportProperties = {
            transportCode: currentTransport.properties.fta,
            subscribedPowers: currentTransport.properties.puissances,
            endpointCommunicationCapability:
              currentTransport.properties.endpointCommunicationCapability ??
              EndpointCommunicationCapability.Local,
          };

          await enedisApiClient.computeTransport(
            {
              transportPerimeter,
              simulationTransportProperties,
              currentTransportProperties,
            },
            PowerTransportPropertyType.Custom
          );
        } catch (err) {
          this.createFailedProfile(perimeter.prm, err.message);
        }
      },
      addTransport(prm, transport) {
        const profileIndex = this.profileList.findIndex((p) => {
          return p.prm === prm;
        });
        const profile = this.profileList[profileIndex];

        const alreadyExistsTransportIndex = profile.transports.findIndex(
          (t) => t.acheminementType === transport.acheminementType
        );

        if (alreadyExistsTransportIndex !== -1)
          profile.transports.splice(alreadyExistsTransportIndex, 1, transport);
        else profile.transports.push(transport);

        profile.status = this.updateProfileStatus(profile);
        profile.transports = transportBudgetMapper.sortTransports(
          profile.transports
        );

        this.profileList.splice(profileIndex, 1, profile);
      },
      updateProfileStatus(profile) {
        const fullTransports = [
          eAcheminementType.eCurrentAcheminement,
          eAcheminementType.eOptimizedAcheminement,
          eAcheminementType.eAjustedAcheminement,
        ];

        const acheminementTypes = profile.transports
          .filter((t) => fullTransports.includes(t.acheminementType))
          .map((t) => t.acheminementType);

        const isProfileComplete =
          profile.technicalData && acheminementTypes.length >= 3;
        if (isProfileComplete) return eJobStatus.Completed;

        const isProfilePartial =
          profile.technicalData &&
          acheminementTypes.includes(eAcheminementType.eCurrentAcheminement);
        if (isProfilePartial) return eJobStatus.Progress;

        return eJobStatus.Active;
      },
      createFailedProfile(prm, message) {
        const profileIndex = this.profileList.findIndex((p) => {
          return p.prm === prm;
        });

        const failedProfile = {
          prm,
          status: eJobStatus.Failed,
          message: message ?? 'Une erreur est survenue',
        };

        return this.profileList.splice(profileIndex, 1, failedProfile);
      },
      selectShortcutYear(year) {
        this.startDateInput = year + '-01-01';
        this.endDateInput = year + '-12-31';
      },
      resetForm() {
        this.numberOfJobsComplete = 0;
        this.profileList = [];
        this.errors = [];
        this.hasTransports = false;
      },
      generateReport(type) {
        this.generatePdfLoading = true;
        if (type === 'details') {
          this.$refs.synthesisDetail.generatePdf();
          MatomoService.trackEvent(
            MATOMO_EVENTS.downloadMultiDetailledSynthesis
          );
        }
        if (type === 'summary') {
          this.$refs.synthesisSummary.generatePdf();
          MatomoService.trackEvent(MATOMO_EVENTS.downloadMultiSummarySynthesis);
        }
      },
      async hasDownloaded() {
        this.generatePdfLoading = false;
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import 'src/client/style/constants.scss';
</style>
