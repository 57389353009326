<template>
  <tr>
    <td class="pa-1 text-left, primary-color">
      <div :class="`${cssAmountType}`">
        {{ title }}
      </div>
      <hr v-if="separate" />
    </td>

    <td
      v-for="(transportAmount, i) in transportAmounts"
      :key="'transport' + i"
      :class="[
        'pa-1 border',
        `${cssAmountType}`,
        cssAcheminementType(transportAmount.acheminementType),
      ]"
    >
      <div class="text-right">
        <AppNumber :value="transportAmount.amounts[amountType]" unit="€" />
      </div>
      <hr v-if="separate" />
    </td>
  </tr>
</template>

<script>
  import { eAcheminementType } from '../../../services/TransportBudget.mapper';
  import AppNumber from '../../app/AppNumber';

  export default {
    name: 'SitePageBudgetDetail',
    components: {
      AppNumber,
    },
    props: {
      title: {
        type: String,
        required: true,
      },
      transportAmounts: {
        type: Array,
        required: true,
      },
      amountType: {
        type: String,
        required: true,
      },
      separate: {
        type: Boolean,
      },
    },
    computed: {
      cssAmountType() {
        if (this.amountType === 'subTotal' || this.amountType === 'total')
          return 'bolder';
        return '';
      },
    },
    methods: {
      cssAcheminementType(acheminementType) {
        if (acheminementType === eAcheminementType.eCurrentAcheminement)
          return 'current';
        if (acheminementType === eAcheminementType.eOptimizedAcheminement)
          return 'optimize';
        if (acheminementType === eAcheminementType.eAjustedAcheminement)
          return 'custom';
      },
      formatAmount(amounts) {
        return new Intl.NumberFormat('fr-FR', {
          style: 'currency',
          currency: 'EUR',
          maximumFractionDigits: 2,
        }).format(amounts[this.amountType]);
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import 'src/client/style/transport-type.scss';
  td {
    font-size: 0.8em;
    color: var(--white);
  }

  tr {
    padding: 0 0.5rem;
  }

  hr {
    margin-top: 0.5rem;
    border: none;
    border-bottom: dashed 1px;
  }

  .bolder {
    font-weight: bold;
  }

  .border {
    border-left: 1rem solid transparent;
  }

  .primary-color {
    color: var(--primary-new);
  }
</style>
