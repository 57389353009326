<template>
  <thead>
    <tr>
      <th class="pa-1 text-left primary-color">Acheminement</th>

      <th
        v-for="(transport, i) in transports"
        :key="'transport' + i"
        :class="[
          cssAcheminementType(transport.acheminementType),
          'pa-1 text-right nowrap',
        ]"
      >
        {{ transport.acheminementType }}
        <template v-if="transport.ajustmentMargin">
          {{ humanReadableMarginModulation(transport.ajustmentMargin) }}
        </template>
      </th>
    </tr>
  </thead>
</template>

<script>
  import { eAcheminementType } from '../../../services/TransportBudget.mapper';

  export default {
    name: 'SitePageBudgetHeader',
    props: {
      transports: {
        type: Array,
        required: true,
      },
    },
    methods: {
      cssAcheminementType(acheminementType) {
        if (acheminementType === eAcheminementType.eCurrentAcheminement)
          return 'current';
        if (acheminementType === eAcheminementType.eOptimizedAcheminement)
          return 'optimize';
        if (acheminementType === eAcheminementType.eAjustedAcheminement)
          return 'custom';
      },
      humanReadableMarginModulation(margin) {
        const positivity = margin >= 0 ? '+' : '';
        if (typeof margin === 'number') return `${positivity} ${margin}%`;
        return '';
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import 'src/client/style/transport-type.scss';
  th {
    font-size: 0.9em;
  }

  thead {
    padding: 0;
  }

  .primary-color {
    color: var(--primary-new);
  }

  .nowrap {
    white-space: nowrap;
  }
</style>
