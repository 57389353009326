<template>
  <v-container class="v-application synthesis-monosite">
    <div v-if="profile.transports?.length > 0" class="mt-2">
      <SynthesisCoverPage :profiles="[profile]"></SynthesisCoverPage>
      <SitePage class="d-flex flex-column" :profile="profile"></SitePage>
    </div>
  </v-container>
</template>

<script>
  import SynthesisCoverPage from './cover-page/SynthesisCoverPage';
  import SitePage from './site-page-itemize/SitePage';

  export default {
    name: 'SynthesisMultiSite',
    components: {
      SynthesisCoverPage,
      SitePage,
    },
    props: {
      profile: {
        type: Object,
        Required: true,
      },
    },
  };
</script>

<style lang="scss" scoped>
  .synthesis-monosite {
    padding: 0 !important;
  }
</style>
