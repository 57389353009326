<template>
  <tr>
    <td class="pa-5">
      {{ prm }}
    </td>

    <td colspan="50" rowspan="2" class="text-left pa-4 error-message">
      {{ errorMessage }}
    </td>
  </tr>
</template>

<script>
  export default {
    name: 'OptimisationProfileError',
    props: {
      prm: {
        type: String,
        required: true,
      },
      message: {
        type: String,
      },
    },
    computed: {
      errorMessage() {
        if (
          this.message.startsWith(
            'soapenv:Server: Demande non recevable : point inexistant:'
          )
        )
          return 'PRM introuvable';

        if (this.message.startsWith('ParseException: no versionUtilisation'))
          return "L'outil ne fonctionne pas pour les PRM raccordés en MU";

        if (this.message.startsWith())
          return "L'outil ne fonctionne pas pour les PRM raccordés en BTINF";

        return this.message;
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import 'src/client/style/table.scss';
</style>
