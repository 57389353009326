<template>
  <td>
    <div
      v-for="(transport, i) in transports"
      :key="'transport' + i"
      :class="[
        'pa-1',
        `${cssAmountType}`,
        cssAcheminementType(transport.acheminementType),
      ]"
    >
      <AppNumber :value="transport[amountType]" :showSign="showSign" unit="€" />
    </div>
  </td>
</template>

<script>
  import { eAcheminementType } from '../../../../services/TransportBudget.mapper';
  import AppNumber from '../../../app/AppNumber';

  export default {
    name: 'SitePageOverviewDetail',
    components: {
      AppNumber,
    },
    props: {
      transports: {
        type: Array,
        required: true,
      },
      amountType: {
        type: String,
        required: true,
      },
      showSign: {
        type: Boolean,
      },
    },
    computed: {
      cssAmountType() {
        if (this.amountType === 'total') return 'bolder';
        return '';
      },
    },
    methods: {
      cssAcheminementType(acheminementType) {
        if (acheminementType === eAcheminementType.eCurrentAcheminement)
          return 'current';
        if (acheminementType === eAcheminementType.eOptimizedAcheminement)
          return 'optimize';
        if (acheminementType === eAcheminementType.eAjustedAcheminement)
          return 'custom';
      },
      formatAmount(amounts) {
        return new Intl.NumberFormat('fr-FR', {
          style: 'currency',
          currency: 'EUR',
          maximumFractionDigits: 2,
        }).format(amounts[this.amountType]);
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import 'src/client/style/transport-type.scss';
  td {
    font-size: 0.8em;
    color: var(--white);
  }

  tr {
    padding: 0 0.5rem;
  }

  hr {
    margin-top: 0.5rem;
    border: none;
    border-bottom: dashed 1px;
  }

  .bolder {
    font-weight: bold;
  }
</style>
