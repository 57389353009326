<template>
  <div class="site-page">
    <SitePageTitle />
    <SitePageSummary :profiles="profiles" />
    <SitePageOverviewTable class="mt-6" :profiles="profiles" />
    <div class="mt-6 site-page__details-title">Détails par compteur</div>
    <SitePageDetailsTable :profiles="profiles" />
  </div>
</template>

<script>
  import SitePageTitle from './SitePageTitle';
  import SitePageSummary from './SitePageSummary';
  import SitePageOverviewTable from './overview/SitePageOverviewTable';
  import SitePageDetailsTable from './details/SitePageDetailsTable';

  export default {
    name: 'SitePage',
    components: {
      SitePageTitle,
      SitePageSummary,
      SitePageOverviewTable,
      SitePageDetailsTable,
    },
    props: {
      profiles: {
        type: Array,
        Required: true,
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import 'src/client/style/constants.scss';

  .site-page {
    padding: 0 1rem;

    &__details-title {
      color: var(--primary-new);
      font-weight: bold;
      margin-left: 2px;
    }
  }
</style>
