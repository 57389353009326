<template>
  <table>
    <SitePageBudgetHeader :transports="transports"></SitePageBudgetHeader>
    <SitePageBudgetContent :transports="transports"></SitePageBudgetContent>
  </table>
</template>

<script>
  import SitePageBudgetHeader from './SitePageBudgetHeader';
  import SitePageBudgetContent from './SitePageBudgetContent';

  export default {
    name: 'SitePageBudgetTable',
    components: {
      SitePageBudgetHeader,
      SitePageBudgetContent,
    },
    props: {
      transports: {
        type: Array,
        required: true,
      },
    },
  };
</script>

<style lang="scss" scoped></style>
