<template>
  <thead>
    <tr :style="{ whiteSpace: 'nowrap' }">
      <th rowspan="2">PRM</th>
      <th rowspan="2">Adresse postale</th>
      <th rowspan="2">Type de compteur</th>
      <th rowspan="2">Acheminement</th>
      <th rowspan="2">Version</th>
      <th v-bind:colspan="lengthTemporalClasses">
        Puissances Souscrites (en Kw)
      </th>
      <th v-bind:colspan="lengthTemporalClasses">Depassements (en h)</th>
      <th rowspan="2">Prix</th>
      <th rowspan="2">Economie</th>
    </tr>
    <tr>
      <th
        v-for="(temporalClass, i) of temporalClasses"
        :key="'temporalClass-power' + i"
      >
        {{ temporalClass }}
      </th>
      <th
        v-for="(temporalClass, i) of temporalClasses"
        :key="'temporalClass-overuse' + i"
      >
        {{ temporalClass }}
      </th>
    </tr>
  </thead>
</template>

<script>
  export default {
    name: 'OptimisationTableHeader',
    props: {
      temporalClasses: {
        type: Array,
        Required: true,
      },
      lengthTemporalClasses: {
        type: Number,
        Required: true,
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import 'src/client/style/table.scss';
</style>
