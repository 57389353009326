<template>
  <td :style="{ whiteSpace: 'nowrap' }">
    <div
      v-for="(transport, i) in transports"
      :key="'datas' + i"
      class="mt-5 mb-5"
    >
      <AppNumber :value="transport.budget.montantHt" unit="€" />
    </div>
  </td>
</template>

<script>
  import AppNumber from '../app/AppNumber';

  export default {
    name: 'OptimisationAmount',
    components: {
      AppNumber,
    },
    props: {
      transports: {
        type: Array,
        Required: true,
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import 'src/client/style/table.scss';
</style>
