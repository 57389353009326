<template>
  <td>
    <div
      v-for="(transport, i) in transports"
      :key="'datas' + i"
      class="mt-5 mb-5"
    >
      <div
        v-if="
          transport.acheminementType === eAcheminementType.eAjustedAcheminement
        "
        class="d-flex custom custom-transport"
      >
        <div v-if="!isPowerCustomOpen" class="d-flex">
          <div class="align-self-center">
            {{ transport.acheminementType }}
            {{ humanReadableMarginModulation(transport.ajustmentMargin) }}
          </div>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-show="!isPowerCustomOpen"
                small
                icon
                plain
                height="15"
                class="ml-1"
                v-on="on"
                @click.prevent="$emit('openPowerCustom')"
              >
                <v-icon color="var(--secondary)">mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span> Modifier les puissances souscrites </span>
          </v-tooltip>
        </div>
        <div v-else class="d-flex">
          <div class="align-self-center">
            {{ transport.acheminementType }}
          </div>
          <v-btn
            v-show="isPowerCustomOpen && !isPowerCustomLoading"
            class="ml-1"
            small
            icon
            plain
            height="15"
            @click.prevent="$emit('cancelPowerCustom')"
          >
            <v-icon color="var(--secondary)">mdi-close</v-icon>
          </v-btn>
          <v-btn
            v-show="isPowerCustomOpen && !isPowerCustomLoading"
            small
            icon
            plain
            height="15"
            @click.prevent="$emit('computeCustomTransport')"
          >
            <v-icon color="var(--secondary)">mdi-check</v-icon>
          </v-btn>
          <v-progress-circular
            v-show="isPowerCustomOpen && isPowerCustomLoading"
            indeterminate
            color="primary"
            loader-height="1"
            size="20"
            width="3"
            class="ml-2"
          ></v-progress-circular>
        </div>
      </div>

      <div v-else :class="cssAcheminementType(transport.acheminementType)">
        {{ transport.acheminementType }}
      </div>
    </div>
  </td>
</template>

<script>
  import { eAcheminementType } from '../../services/TransportBudget.mapper';

  export default {
    name: 'OptimisationTransportType',
    props: {
      transports: {
        type: Array,
        Required: true,
      },
      isPowerCustomOpen: {
        type: Boolean,
      },
      isPowerCustomLoading: {
        type: Boolean,
      },
    },
    data() {
      return {
        eAcheminementType,
      };
    },
    methods: {
      cssAcheminementType(acheminementType) {
        if (acheminementType === eAcheminementType.eCurrentAcheminement)
          return 'current';
        if (acheminementType === eAcheminementType.eOptimizedAcheminement)
          return 'optimize';
        if (acheminementType === eAcheminementType.eAjustedAcheminement)
          return 'custom';
      },
      humanReadableMarginModulation(ajustmentMargin) {
        const positivity = ajustmentMargin >= 0 ? '+' : '';

        if (typeof ajustmentMargin === 'number')
          return `${positivity} ${ajustmentMargin}%`;

        return '';
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import 'src/client/style/table.scss';
  @import 'src/client/style/transport-type.scss';

  td {
    font-weight: bold;
  }

  .custom-transport {
    white-space: nowrap;
    justify-content: center;
  }
</style>
