var render = function render(){var _vm=this,_c=_vm._self._c;return _c('table',[_c('thead',[_c('tr',[_c('th',{class:[
          _vm.cssAcheminementType(_vm.transport.acheminementType),
          'first-column',
        ]},[_vm._v(" Acheminement "+_vm._s(_vm.transport.acheminementType.toLowerCase())+" ")]),_vm._l((_vm.temporalClasses),function(temporalClass,i){return _c('th',{key:'temporalClass' + i,class:_vm.cssAcheminementType(_vm.transport.acheminementType)},[_vm._v(" "+_vm._s(temporalClass)+" ")])}),_c('div',{style:({ width: '5px' })}),_c('th',{class:[
          _vm.cssAcheminementType(_vm.transport.acheminementType),
          'last-column',
        ]},[_vm._v(" VERSION ")])],2)]),_c('tbody',[_c('tr',[_c('td',{staticClass:"first-column"},[_vm._v("Puissance souscrite (kW)")]),_vm._l((_vm.temporalClasses),function(temporalClass,i){return _c('td',{key:'powers' + i},[_vm._v(" "+_vm._s(_vm.transport.properties.puissances[temporalClass])+" ")])}),_c('div'),_c('td',[_vm._v(" "+_vm._s(_vm.transport.properties.versionUtilisation)+" ")])],2),_c('tr',[_c('td',{staticClass:"first-column"},[_vm._v("Dépassement de puissance (kW)")]),_vm._l((_vm.temporalClasses),function(temporalClass,i){return _c('td',{key:'overuse-powers' + i},[_c('AppNumber',{attrs:{"value":_vm.transport.cdpAnalyse.puissanceDepassementByTemporalClass[
              temporalClass
            ]}})],1)})],2),_c('tr',[_c('td',{staticClass:"first-column"},[_vm._v("Dépassement (h)")]),_vm._l((_vm.temporalClasses),function(temporalClass,i){return _c('td',{key:'overuse-hours' + i},[_c('AppNumber',{attrs:{"value":_vm.transport.cdpAnalyse.nbHoursDepassementByTemporalClass[
              temporalClass
            ]}})],1)})],2),_c('tr',[_c('td',{staticClass:"first-column"},[_vm._v("Dépassement (€)")]),_vm._l((_vm.temporalClasses),function(temporalClass,i){return _c('td',{key:'overuse-amount' + i},[_c('AppNumber',{attrs:{"value":_vm.transport.budget.composanteDepassement.amounts[temporalClass]}})],1)})],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }