<template>
  <thead>
    <tr class="headers">
      <th v-for="(header, i) in headers" :key="'header' + i" class="header">
        {{ header }}
      </th>
    </tr>
  </thead>
</template>

<script>
  export default {
    name: 'SitePageOverviewHeader',
    data() {
      return {
        headers: [
          'Récapitulatif',
          'Volume total',
          '',
          'Gestion + Comptage',
          'Part Fixe',
          'Part Variable',
          'Taxes',
          'Coût de modification',
          'Total',
          'Gain',
        ],
      };
    },
  };
</script>

<style lang="scss" scoped>
  .headers {
    white-space: 'nowrap';
    font-size: 0.8em;
  }

  .header {
    color: var(--primary-new);
    font-weight: 400;
  }

  .header:first-child {
    font-size: 1.3em;
    font-weight: bold;
    text-align: left;
  }

  .header:last-child {
    font-weight: bold;
  }
</style>
