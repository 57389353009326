<template>
  <div class="site-page-summary">
    <span class="mr-4"> Prm: {{ formatPrm(profile) }} </span>

    <div class="site-page-summary__pipe"></div>

    <span class="ml-4 mr-4">
      {{ profile.technicalData.typeCompteur }}
    </span>

    <div class="site-page-summary__pipe"></div>

    <span class="ml-4">
      du
      {{ formatDate(profile.startDate) }} au
      {{ formatDate(profile.endDate) }}
    </span>
  </div>
</template>

<script>
  import moment from 'moment';
  export default {
    name: 'SitePageSummary',
    props: {
      profile: {
        type: Object,
        Required: true,
      },
    },
    methods: {
      formatPrm(profile) {
        if (profile.prm.length === 14) {
          const prm = profile.prm.toString();

          return `${prm.slice(0, 2)} ${prm
            .slice(2)
            .match(/.{1,3}/g)
            .join(' ')}`;
        }

        return profile.prm;
      },
      formatDate(dateTime) {
        return moment(dateTime).format('DD/MM/YYYY');
      },
    },
  };
</script>

<style lang="scss" scoped>
  .site-page-summary {
    display: flex;
    justify-content: center;
    color: var(--primary-new);
    width: 100%;
    border: 1px solid var(--primary-new);
    padding: 0.5rem;
    font-size: 1.4em;

    &__pipe {
      width: 2px;
      background-color: var(--primary-new);
    }
  }
</style>
