<template>
  <td>
    <div
      v-for="(transport, i) in transports"
      :key="'datas' + i"
      class="mt-5 mb-5"
    >
      <div
        v-if="
          transport.acheminementType ===
            eAcheminementType.eAjustedAcheminement && isPowerCustomOpen
        "
        class="d-flex justify-center"
      >
        <div>
          <v-select
            class="select"
            @input="$emit('updateVersionUtilisation', $event)"
            dense
            hide-details
            height="20"
            :disabled="isPowerCustomLoading"
            :items="versionUtilisationTypes"
            :value="transport.properties.versionUtilisation"
          />
        </div>
      </div>

      <div v-else :class="[cssAcheminementType(transport), 'mt-5 mb-5']">
        {{ transport.properties.versionUtilisation }}
      </div>
    </div>
  </td>
</template>

<script>
  import { eAcheminementType } from '../../services/TransportBudget.mapper';

  export default {
    name: 'OptimisationConsumptionProfile',
    props: {
      transports: {
        type: Array,
        Required: true,
      },
      isPowerCustomOpen: {
        type: Boolean,
      },
      isPowerCustomLoading: {
        type: Boolean,
      },
    },
    data() {
      return {
        data: this.profile,
        versionUtilisationTypes: ['CU', 'LU'],
        eAcheminementType,
      };
    },
    methods: {
      cssAcheminementType(transport) {
        if (
          transport.acheminementType === eAcheminementType.eCurrentAcheminement
        )
          return 'current';

        const actualVersionUtilisation = this.transports.find(
          (transport) =>
            transport.acheminementType ===
            eAcheminementType.eCurrentAcheminement
        )?.properties.versionUtilisation;

        if (
          !actualVersionUtilisation ||
          actualVersionUtilisation === transport.properties.versionUtilisation
        )
          return 'current';

        if (
          transport.acheminementType ===
          eAcheminementType.eOptimizedAcheminement
        )
          return 'optimize';
        if (
          transport.acheminementType === eAcheminementType.eAjustedAcheminement
        )
          return 'custom';
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import 'src/client/style/table.scss';
  @import 'src/client/style/transport-type.scss';

  .select {
    width: 3rem;
    font-size: 0.9em;
    padding: 0;
    margin: 0;
  }
</style>
