import { render, staticRenderFns } from "./OptimisationItem.vue?vue&type=template&id=6a93151e&scoped=true"
import script from "./OptimisationItem.vue?vue&type=script&lang=js"
export * from "./OptimisationItem.vue?vue&type=script&lang=js"
import style0 from "./OptimisationItem.vue?vue&type=style&index=0&id=6a93151e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a93151e",
  null
  
)

export default component.exports