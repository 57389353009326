<template>
  <div>
    <v-menu down :offset-y="true">
      <template v-slot:activator="{ on }">
        <v-btn x-small plain v-on="on">
          <v-icon v-if="!generatePdfLoading">{{ icon }}</v-icon>
          <v-progress-circular
            v-else
            indeterminate
            color="primary"
            loader-height="1"
            size="18"
            width="2"
            class="mr-2"
          />

          {{ title }}
        </v-btn>
      </template>
      <v-list class="d-flex flex-column pa-0 font">
        <button
          class="pa-2 button"
          height="auto"
          @click.prevent="$emit('downloadSynthesisDetails')"
        >
          Synthèse détaillée
        </button>

        <button
          class="pa-2 button"
          height="auto"
          @click.prevent="$emit('downloadSynthesisSummary')"
        >
          Synthèse résumée
        </button>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
  export default {
    name: 'OptimisationSynthesisDownloader',
    props: {
      title: {
        type: String,
        required: true,
      },
      generatePdfLoading: {
        type: Boolean,
        required: true,
      },
      icon: {
        required: true,
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import 'src/client/style/constants.scss';
  .font {
    font-size: 0.8em;
  }

  .button:hover {
    transition: 0.3s;
    background-color: var(--secondary-grey-new);
  }
</style>
