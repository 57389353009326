<template>
  <tr>
    <OptimisationIdentification
      :prm="profile.prm"
      :status="profile.status"
      :togglePdfLoading="togglePdfLoading"
      @setProfileForPdf="$emit('setProfileForPdf', profile)"
    />

    <td v-if="profile.pointLivraison">
      <ProfileAddress :address="profile.pointLivraison.address" />
    </td>
    <td v-if="profile.pointLivraison">
      {{ profile.technicalData.typeCompteur }}
    </td>

    <OptimisationTransportType
      v-if="profile.transports?.length"
      class="optimisation-turpe-item__item"
      :transports="profile.transports"
      :isPowerCustomOpen="isPowerCustomOpen"
      :isPowerCustomLoading="isPowerCustomLoading"
      @openPowerCustom="isPowerCustomOpen = true"
      @cancelPowerCustom="cancelPowerCustom"
      @computeCustomTransport="computeCustomTransport"
    />

    <OptimisationConsumptionProfile
      v-if="profile.transports?.length"
      class="optimisation-turpe-item__item"
      :transports="profile.transports"
      :isPowerCustomOpen="isPowerCustomOpen"
      :isPowerCustomLoading="isPowerCustomLoading"
      @updateVersionUtilisation="updateVersionUtilisation"
    />

    <OptimizationPowers
      v-for="(poste, i) of temporalClasses"
      :key="'powers' + i"
      class="optimisation-turpe-item__item"
      :temporalClass="poste"
      :transports="profile.transports"
      :isPowerCustomOpen="isPowerCustomOpen"
      :isPowerCustomLoading="isPowerCustomLoading"
      :customTransport="customProfile.transports[customTransportIndex]"
      @updatePower="updatePower"
      @updatePowersOnBlur="updatePowersOnBlur"
    />

    <OptimizationOveruse
      v-for="(poste, i) of temporalClasses"
      :key="'overuse' + i"
      class="optimisation-turpe-item__item"
      :temporalClass="poste"
      :transports="profile.transports"
    />

    <OptimisationAmount
      v-if="profile.transports?.length"
      class="optimisation-turpe-item__item"
      :transports="profile.transports"
    />

    <OptimisationBenefits
      v-if="profile.transports?.length"
      class="optimisation-turpe-item__item"
      :transports="profile.transports"
    />
  </tr>
</template>

<script>
  import { classicTemporalClass } from '../../constants/posteHorosaisonnierOrder.constants';

  import ProfileAddress from '../app/AppProfileAddress';
  import OptimisationIdentification from './OptimisationIdentification';
  import OptimisationConsumptionProfile from './OptimisationConsumptionProfile';
  import OptimisationAmount from './OptimisationAmount';
  import OptimisationBenefits from './OptimisationBenefits';
  import OptimisationTransportType from './OptimisationTransportType';
  import OptimizationPowers from './OptimizationPowers';
  import OptimizationOveruse from './OptimizationOveruse';
  import { TransportCodeUtils } from '@Collectif-Energie/collective-lib';
  import { eAcheminementType } from '../../services/TransportBudget.mapper';
  import {
    MatomoService,
    MATOMO_EVENTS,
  } from './../../services/MatomoApi.service';
  export default {
    name: 'OptimisationItem',
    components: {
      OptimisationIdentification,
      ProfileAddress,
      OptimisationTransportType,
      OptimisationAmount,
      OptimisationBenefits,
      OptimizationPowers,
      OptimizationOveruse,
      OptimisationConsumptionProfile,
    },
    props: {
      profile: {
        type: Object,
        Required: true,
      },
      temporalClasses: {
        type: Array,
        Required: true,
      },
      isComputeCustomTransport: {
        type: Boolean,
      },
      togglePdfLoading: {
        type: Boolean,
        Required: true,
      },
    },
    data() {
      return {
        profileForPdf: {},
        isPowerCustomOpen: false,
        isPowerCustomLoading: false,
      };
    },
    watch: {
      isComputeCustomTransport() {
        if (this.isComputeCustomTransport === true) {
          (this.isPowerCustomOpen = true), (this.isPowerCustomLoading = true);
        }
      },
    },
    computed: {
      customProfile() {
        this.closePowerCustom();
        return JSON.parse(JSON.stringify(this.profile));
      },
      customTransportIndex() {
        return this.customProfile?.transports?.findIndex(
          (transport) =>
            transport.acheminementType ===
            eAcheminementType.eAjustedAcheminement
        );
      },
      customTransport() {
        return this.customProfile?.transports?.find(
          (transport) =>
            transport.acheminementType ===
            eAcheminementType.eAjustedAcheminement
        );
      },
    },
    methods: {
      closePowerCustom() {
        this.isPowerCustomOpen = false;
        this.isPowerCustomLoading = false;
      },
      updateVersionUtilisation(event) {
        this.customTransport.properties.versionUtilisation = event;
      },
      updatePower(event, temporalClass) {
        if (event === null || event === undefined) {
          const actualPower =
            this.profile.transports[this.customTransportIndex].properties
              .puissances[temporalClass];

          this.customTransport.properties.puissances[temporalClass] =
            actualPower;
        } else {
          this.customTransport.properties.puissances[temporalClass] =
            Math.round(Number(event));
        }
      },
      updatePowersOnBlur() {
        classicTemporalClass.forEach((temporalClass, i) => {
          const previousTemporalClass = classicTemporalClass[i - 1];

          const previousPower =
            this.customTransport.properties.puissances[previousTemporalClass];

          if (!previousPower) return;

          const actualPower =
            this.customTransport.properties.puissances[temporalClass];

          if (actualPower < previousPower)
            this.customTransport.properties.puissances[temporalClass] =
              previousPower;
        });
      },
      cancelPowerCustom() {
        this.isPowerCustomOpen = false;

        this.customTransport.properties.puissances = JSON.parse(
          JSON.stringify(
            this.profile.transports[this.customTransportIndex].properties
              .puissances
          )
        );
      },
      async computeCustomTransport() {
        MatomoService.trackEvent(
          MATOMO_EVENTS.computeCustomTransport,
          this.customTransport.properties.versionUtilisation
        );

        const transport = JSON.parse(
          JSON.stringify(
            this.customProfile.transports.find(
              (t) =>
                t.acheminementType === eAcheminementType.eAjustedAcheminement
            )
          )
        );

        if (!transport) return;

        this.isPowerCustomLoading = true;

        const perimeter = {
          prm: this.customProfile.prm,
          startDate: this.customProfile.startDate,
          endDate: this.customProfile.endDate,
        };
        const properties = JSON.parse(JSON.stringify(transport.properties));
        properties.fta = TransportCodeUtils.getTransportCode(
          properties.typeRaccordement,
          properties.versionUtilisation
        );

        this.$emit('computeCustomTransport', perimeter, properties, null);
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import 'src/client/style/table.scss';
  td {
    background-color: transparent !important;
  }

  .optimisation-turpe-item {
    &__item {
      padding: 0 0.5rem;
    }
  }
</style>
