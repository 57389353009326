import { Moment } from 'moment';

import { EndpointCommunicationCapability } from '@Collectif-Energie/collective-lib';
import { BudgetAcheminement } from '../budgets-lib/power/acheminement/budgets-acheminement.interface';
import {
  TypeCompteur,
  TypeRaccordement,
  VersionUtilisation,
} from '../commons/enums';
import { Fta, PowerByTemporalClass } from '../commons/types';
import {
  NewProfilEnergetique,
  PointLivraisonDto,
} from '../delivery-points/models/delivery-point.interface';
import { CdpAnalyseResume } from '../measurements/models/measurement.interface';

export interface Acheminement {
  acheminementType: eAcheminementType;
  properties: AcheminementProperties;
  budget: BudgetAcheminement;
  cdpAnalyse: CdpAnalyseResume;
}

export enum eAcheminementType {
  eCurrentAcheminement = 'Actuel',
  eOptimizedAcheminement = 'Optimisé',
  eAjustedAcheminement = 'Modulé',
}

export interface AcheminementProperties {
  fta: Fta;
  typeCompteur: TypeCompteur;
  typeRaccordement: TypeRaccordement;
  puissanceRaccordement?: number;
  versionUtilisation: VersionUtilisation;
  puissances: PowerByTemporalClass;
  programmationPosteHoraire?: string;
  optionTarifaireFournisseur?: string;
  endpointCommunicationCapability?: EndpointCommunicationCapability;
}

export interface AcheminementResult {
  prm: string;
  margin: number;
  startDate: Moment;
  endDate: Moment;
  acheminement: Acheminement;
}

export interface AcheminementRequest {
  prms: string[];
  margin: number;
  startDate: Moment;
  endDate: Moment;
  username: string;
}

export interface AcheminementParameters {
  prm: string;
  margin: number;
  startDate: Moment;
  endDate: Moment;
  username: string;
}

export interface AcheminementParametersAndProfile {
  parameters: AcheminementParameters;
  profilEnergetique: NewProfilEnergetique;
}

export interface DownloadCsvAcheminementRequest {
  profiles: FullProfilEnergetiqueDTO[];
}

export interface FullProfilEnergetiqueDTO {
  prm: string;
  startDate: Moment;
  endDate: Moment;
  pointLivraison: PointLivraisonDto;
  actual: Acheminement;
  optimize: Acheminement;
  ajusted: Acheminement;
  ajustmentMargin: number;
}
