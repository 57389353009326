<template>
  <tbody class="site-page-budget-content">
    <SitePageBudgetDetail
      title="Gestion + comptage"
      amountType="accoutingAndAdministration"
      :transportAmounts="transportAmounts"
    />

    <SitePageBudgetDetail
      title="Part fixe"
      amountType="infrastructuralPower"
      :transportAmounts="transportAmounts"
    />

    <SitePageBudgetDetail
      title="Part variable"
      :transportAmounts="transportAmounts"
      amountType="infrastructuralEnergy"
    />

    <SitePageBudgetDetail
      title="Dépassement"
      :transportAmounts="transportAmounts"
      amountType="overuse"
      :separate="true"
    />

    <SitePageBudgetDetail
      title="Sous total"
      :transportAmounts="transportAmounts"
      amountType="subTotal"
    />

    <SitePageBudgetDetail
      title="CTA"
      :transportAmounts="transportAmounts"
      amountType="cta"
    />

    <SitePageBudgetDetail
      v-if="hasTransportWithEndpointServiceCosts"
      :title="`Coûts de modification ${
        hasUnpredictedEndpointServiceCosts ? '*' : ''
      }`"
      :transportAmounts="transportAmounts"
      amountType="endpointServiceCostBudget"
    />

    <SitePageBudgetDetail
      title="Total"
      :transportAmounts="transportAmounts"
      amountType="total"
    />

    <SitePageBudgetFooter :transports="transports" />

    <tr v-if="hasUnpredictedEndpointServiceCosts">
      <td
        colspan="4"
        class="site-page-budget-content__endpoint-service-cost-warning"
      >
        * Attention des frais supplémentaires risques d'être appliqués avec un
        changement de compteur et/ou des travaux de transformation
      </td>
    </tr>
  </tbody>
</template>

<script>
  import { EndpointConnection } from '@Collectif-Energie/collective-lib';
  import SitePageBudgetDetail from './SitePageBudgetDetail';
  import SitePageBudgetFooter from './SitePageBudgetFooter';

  export default {
    name: 'SitePageBudgetContent',
    components: {
      SitePageBudgetDetail,
      SitePageBudgetFooter,
    },
    props: {
      transports: {
        type: Array,
        required: true,
      },
    },
    computed: {
      transportAmounts() {
        return this.transports.map((transport) => ({
          acheminementType: transport.acheminementType,
          amounts: {
            accoutingAndAdministration:
              transport.budget.composanteComptage.amountExcludingVat +
              transport.budget.composanteGestion.amountExcludingVat,
            infrastructuralPower:
              transport.budget.composanteSoutirage.power.total,
            infrastructuralEnergy:
              transport.budget.composanteSoutirage.energy.total,
            overuse: transport.budget.composanteDepassement.amountExcludingVat,
            subTotal:
              transport.budget.composanteComptage.amountExcludingVat +
              transport.budget.composanteGestion.amountExcludingVat +
              transport.budget.composanteSoutirage.amountExcludingVat +
              transport.budget.composanteDepassement.amountExcludingVat,
            cta: transport.budget.taxes.contributionTarifaireAcheminement
              .montantHt,
            endpointServiceCostBudget:
              transport.budget.endpointServiceCostBudget.amountExcludingVat,
            total: transport.budget.montantHt,
          },
        }));
      },
      hasTransportWithEndpointServiceCosts() {
        return this.transports.some((transport) => {
          return Boolean(
            transport.budget.endpointServiceCostBudget.transportCodeBudget ||
              transport.budget.endpointServiceCostBudget.subscribedPowerBudget
          );
        });
      },
      hasUnpredictedEndpointServiceCosts() {
        return this.transports.some((t) => {
          return Object.values(t.properties.puissances).some((power) => {
            return Boolean(
              power >= 230 &&
                t.properties.typeRaccordement !== EndpointConnection.HTA
            );
          });
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  td {
    background-color: var(--white);
  }

  .site-page-budget-content {
    &__endpoint-service-cost-warning {
      padding-top: 0.5rem;
      font-size: 0.75em;
      color: var(--primary-new);
    }
  }
</style>
