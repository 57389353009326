<template>
  <div>
    <table
      v-for="(profiles, i) in paginatedProfiles"
      :key="'profiles' + i"
      class="html2pdf__page-break mt-6"
    >
      <SitePageDetailsHeader
        :temporalClasses="temporalClasses"
        :lengthTemporalClasses="lengthTemporalClasses"
      />
      <tbody>
        <SitePageDetailsRow
          v-for="(profile, i) in profiles"
          :key="'profile' + i"
          class="page-break-inside border-row"
          :profile="profile"
          :temporalClasses="temporalClasses"
        />
      </tbody>
    </table>
  </div>
</template>

<script>
  import posteHorosaisonnierOrder, {
    orderedTemporalClass,
  } from '../../../../constants/posteHorosaisonnierOrder.constants';
  import SitePageDetailsHeader from './SitePageDetailsHeader';
  import SitePageDetailsRow from './SitePageDetailsRow';

  export default {
    name: 'SitePageDetailsTable',
    components: { SitePageDetailsHeader, SitePageDetailsRow },
    props: {
      profiles: {
        type: Array,
        Required: true,
      },
    },
    computed: {
      paginatedProfiles() {
        return this.paginate(this.profiles.slice(0, 4), 4).concat(
          this.paginate(this.profiles.slice(4), 8)
        );
      },
      lengthTemporalClasses() {
        let lengthTemporalClasses = [];
        for (const profile of this.profiles) {
          if (profile.technicalData) {
            lengthTemporalClasses.push(
              Object.keys(profile.technicalData.puissances).length
            );
          }
        }
        return Math.max(...lengthTemporalClasses);
      },
      temporalClasses() {
        let temporalClasses = [];
        for (const profile of this.profiles) {
          if (
            profile.technicalData &&
            Object.keys(profile.technicalData.puissances).length >
              temporalClasses.length
          ) {
            orderedTemporalClass.forEach((ct) => {
              if (Object.keys(profile.technicalData.puissances).includes(ct)) {
                temporalClasses.push(ct);
              }
            });
          }
        }

        return [...new Set(temporalClasses)].sort(function (a, b) {
          const orderA = posteHorosaisonnierOrder[a] || 1000;
          const orderB = posteHorosaisonnierOrder[b] || 1001;
          return orderA - orderB;
        });
      },
    },
    methods: {
      paginate(arr, size) {
        return arr.reduce((acc, val, i) => {
          const idx = Math.floor(i / size);
          const page = acc[idx] || (acc[idx] = []);
          page.push(val);

          return acc;
        }, []);
      },
    },
  };
</script>

<style lang="scss" scoped>
  table {
    border-spacing: 2px 0;
  }

  .page-break-inside {
    page-break-inside: avoid;
  }

  tr:first-child {
    border-top: 2px solid #0047bb !important;
  }

  tr:last-child {
    border-bottom: 2px solid #0047bb !important;
  }

  .border-row {
    border-left: 2px solid #0047bb;
    border-right: 2px solid #0047bb;
    border-top: 1px solid #0047bb;
    border-bottom: 1px solid #0047bb;
  }
</style>
