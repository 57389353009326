<template>
  <v-container fluid>
    <v-row xs12 :style="{ overflowX: 'auto' }">
      <v-col>
        <table>
          <OptimisationTableHeader
            :lengthTemporalClasses="lengthTemporalClasses"
            :temporalClasses="temporalClasses"
          />

          <tbody
            v-for="(profile, i) in profiles"
            :key="'datas' + i"
            :class="{ light: i % 2 == 0, shadow: i % 2 == 1 }"
          >
            <OptimisationProfileActive
              v-if="profile.status === eJobStatus.Active"
              :prm="profile.prm"
            />

            <OptimisationProfileError
              v-else-if="profile.status === eJobStatus.Failed"
              :prm="profile.prm"
              :message="profile.message"
            />

            <OptimisationItem
              v-else
              :profile="profile"
              :temporalClasses="temporalClasses"
              :isComputeCustomTransport="isComputeCustomTransport"
              :togglePdfLoading="togglePdfLoading"
              @setProfileForPdf="setProfileForPdf"
              @computeCustomTransport="computeCustomTransport"
            />
          </tbody>
        </table>
      </v-col>
    </v-row>
    <div :style="{ display: 'none' }">
      <vue-html2pdf
        :show-layout="false"
        :float-layout="false"
        :enable-download="true"
        :preview-modal="true"
        :manual-pagination="true"
        pdf-content-width="795px"
        @beforeDownload="beforeDownloadPdf($event)"
        @hasDownloaded="hasDownloaded"
        ref="html2Pdf"
      >
        <SynthesisMonoSite slot="pdf-content" :profile="profileForPdf" />
      </vue-html2pdf>
    </div>
  </v-container>
</template>

<script>
  import posteHorosaisonnierOrder, {
    orderedTemporalClass,
  } from '../../constants/posteHorosaisonnierOrder.constants';

  import OptimisationProfileError from './OptimisationProfileError';
  import OptimisationProfileActive from './OptimisationProfileActive';
  import SynthesisMonoSite from '../synthesis/SynthesisMonoSite.vue';
  import OptimisationItem from './OptimisationItem.vue';
  import OptimisationTableHeader from './OptimisationTableHeader.vue';
  import VueHtml2pdf from 'vue-html2pdf';
  import { eJobStatus } from '../../services/TransportBudget.mapper';

  import {
    MatomoService,
    MATOMO_EVENTS,
  } from './../../services/MatomoApi.service';

  export default {
    name: 'OptimisationTable',
    components: {
      OptimisationItem,
      OptimisationProfileActive,
      OptimisationProfileError,
      OptimisationTableHeader,
      VueHtml2pdf,
      SynthesisMonoSite,
    },
    props: {
      prms: {
        type: Array,
        Required: true,
      },
      profiles: {
        type: Array,
        Required: true,
      },
      isComputeCustomTransport: {
        type: Boolean,
      },
    },
    data() {
      return {
        eJobStatus,
        profileForPdf: {},
        togglePdfLoading: false,
      };
    },
    computed: {
      lengthTemporalClasses() {
        let lengthTemporalClasses = [];
        for (const profile of this.profiles) {
          if (profile.technicalData) {
            lengthTemporalClasses.push(
              Object.keys(profile.technicalData.puissances).length
            );
          }
        }
        return Math.max(...lengthTemporalClasses);
      },
      temporalClasses() {
        let temporalClasses = [];
        for (const profile of this.profiles) {
          if (
            profile.technicalData &&
            Object.keys(profile.technicalData.puissances).length >
              temporalClasses.length
          ) {
            orderedTemporalClass.forEach((ct) => {
              if (Object.keys(profile.technicalData.puissances).includes(ct)) {
                temporalClasses.push(ct);
              }
            });
          }
        }

        return [...new Set(temporalClasses)].sort(function (a, b) {
          const orderA = posteHorosaisonnierOrder[a] || 1000;
          const orderB = posteHorosaisonnierOrder[b] || 1001;
          return orderA - orderB;
        });
      },
    },
    methods: {
      computeCustomTransport(perimeter, properties, margin) {
        this.$emit('computeCustomTransport', perimeter, properties, margin);
      },
      setProfileForPdf(profile) {
        this.profileForPdf = profile;
        this.generateReport();
      },
      async beforeDownloadPdf({ html2pdf, options, pdfContent }) {
        options = {
          filename: `OPTIMISATION TURPE PRM: ${this.profileForPdf.prm}`,
          html2canvas: {
            scale: 2,
          },
          jsPDF: {
            format: 'a4',
            orientation: 'portrait',
          },
        };
        await html2pdf().set(options).from(pdfContent).save();
      },
      generateReport() {
        this.$refs.html2Pdf.generatePdf();
      },
      async hasDownloaded() {
        MatomoService.trackEvent(
          MATOMO_EVENTS.downloadMonoSynthesis,
          this.profileForPdf.prm
        );

        this.togglePdfLoading = !this.togglePdfLoading;
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import 'src/client/style/table.scss';
  .light {
    background-color: #f9f9f9 !important;
  }
  .shadow {
    background-color: #eeeeee !important;
  }
  td {
    background-color: transparent !important;
  }
</style>
