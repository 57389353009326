<template>
  <div class="html2pdf__page-break synthesis-cover-page">
    <SynthesisCoverPageHeaderSummary v-if="isLandscape" />
    <SynthesisCoverPageHeaderDetails v-else />

    <div>
      <p class="synthesis-cover-page__title">Optimisation TURPE</p>
    </div>
    <table>
      <tbody>
        <tr>
          <td class="synthesis-cover-page__table--head">Période d'analyse</td>
          <td class="synthesis-cover-page__table--body">
            Du {{ formatDate(profiles[0].startDate) }} au
            {{ formatDate(profiles[0].endDate) }}
          </td>
        </tr>
        <tr>
          <td class="synthesis-cover-page__table--head">Périmètre analysé</td>
          <td class="synthesis-cover-page__table--body">
            {{ endpointAnalyse }}
          </td>
        </tr>
        <tr>
          <td class="synthesis-cover-page__table--head">
            Total des gains estimés
          </td>
          <td
            class="synthesis-cover-page__table--body synthesis-cover-page__benefits"
          >
            <AppNumber :value="transportBenefits" :showSign="true" unit="€" />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  import moment from 'moment-timezone';
  import AppNumber from '../../app/AppNumber';
  import logo from '../../../assets/img/logo.png';
  import cover from '../../../assets/img/cover-pdf.png';
  import SynthesisCoverPageHeaderDetails from './SynthesisCoverPageHeaderDetails';
  import SynthesisCoverPageHeaderSummary from './SynthesisCoverPageHeaderSummary';
  import { PowerEndpointCategory } from '@Collectif-Energie/collective-lib';
  import { eAcheminementType } from '../../../services/TransportBudget.mapper';

  export default {
    name: 'SynthesisCoverPage',
    components: {
      AppNumber,
      SynthesisCoverPageHeaderDetails,
      SynthesisCoverPageHeaderSummary,
    },
    props: {
      profiles: {
        type: Array,
        Required: true,
      },
      isLandscape: {
        type: Boolean,
        Required: true,
      },
    },
    data() {
      return {
        logo,
        cover,
      };
    },
    computed: {
      transportBenefits() {
        let totalCurrentAcheminement = 0;
        let totalOptimizeAcheminement = 0;
        for (const profile of this.profiles) {
          const currentBudget = profile.transports.find(
            (transport) =>
              transport.acheminementType ===
              eAcheminementType.eCurrentAcheminement
          ).budget.montantHt;
          const optimizeBudget = profile.transports.find(
            (transport) =>
              transport.acheminementType ===
              eAcheminementType.eOptimizedAcheminement
          ).budget.montantHt;

          totalCurrentAcheminement += currentBudget;
          totalOptimizeAcheminement += optimizeBudget;
        }
        return totalCurrentAcheminement - totalOptimizeAcheminement;
      },
      endpointAnalyse() {
        let result = '';

        const parsedProfiles = this.profiles.map((profile) => ({
          endpointCategory: profile.technicalData.typeCompteur,
          totalConsumption: profile.transports[0].cdpAnalyse.consommationTotale,
        }));

        Object.keys(PowerEndpointCategory).forEach((endpointCategory) => {
          const concernedProfiles = parsedProfiles.filter(
            (profile) => profile.endpointCategory === endpointCategory
          );

          if (concernedProfiles.length) {
            const totalConsumptions = concernedProfiles.reduce(
              (total, profile) => total + profile.totalConsumption,
              0
            );

            result += `${
              concernedProfiles.length
            } ${endpointCategory} [${new Intl.NumberFormat('fr-FR', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(totalConsumptions)} MWh] - `;
          }
        });

        return result.substring(0, result.length - 2);
      },
    },
    methods: {
      formatDate(dateTime) {
        return moment(dateTime).format('DD/MM/YYYY');
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import 'src/client/style/constants.scss';
  table {
    background-color: var(--white);
    border-spacing: 0 0.2rem;
  }

  td {
    background-color: var(--white);
    padding: 0.5rem;
    font-size: 0.8em;
    border: 1px solid var(--primary-new);
  }

  .synthesis-cover-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 790px;
    padding: 1rem;

    &__title {
      color: var(--white);
      background-color: var(--primary-new);
      font-weight: bold;
      font-size: 2em;
      padding: 1.5rem 1.5rem 1.5rem 4rem;
    }

    &__table {
      &--head {
        color: var(--white);
        background-color: var(--primary-new);
        font-weight: bold;
        width: 30%;
      }

      &--body {
        color: var(--primary-new);
        margin-left: 0.5rem;
      }
    }

    &__benefits {
      font-weight: bold;
    }
  }
</style>
