<template>
  <td>
    <div v-for="(transport, i) in transports" :key="'datas' + i">
      <div :class="[cssAcheminementType(transport), 'mt-5 mb-5']">
        <AppNumber
          :value="
            transport.cdpAnalyse.nbHoursDepassementByTemporalClass[
              temporalClass
            ]
          "
        />
      </div>
    </div>
  </td>
</template>

<script>
  import AppNumber from '../app/AppNumber';
  import { eAcheminementType } from '../../services/TransportBudget.mapper';

  export default {
    name: 'OptimizationOveruse',
    components: {
      AppNumber,
    },
    props: {
      transports: {
        type: Array,
        Required: true,
      },
      temporalClass: {
        type: String,
        Required: true,
      },
    },
    methods: {
      cssAcheminementType(transport) {
        if (
          transport.acheminementType === eAcheminementType.eCurrentAcheminement
        )
          return 'current';

        const actualOveruse = this.transports.find(
          (transport) =>
            transport.acheminementType ===
            eAcheminementType.eCurrentAcheminement
        )?.cdpAnalyse.nbHoursDepassementByTemporalClass[this.temporalClass];

        if (
          actualOveruse ===
          transport.cdpAnalyse.nbHoursDepassementByTemporalClass[
            this.temporalClass
          ]
        )
          return 'current';

        if (
          transport.acheminementType ===
          eAcheminementType.eOptimizedAcheminement
        )
          return 'optimize';
        if (
          transport.acheminementType === eAcheminementType.eAjustedAcheminement
        )
          return 'custom';
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import 'src/client/style/table.scss';
  @import 'src/client/style/transport-type.scss';
</style>
