<template>
  <tr>
    <td class="pl-2">
      <div class="font-weight-bold">{{ profile.prm }}</div>
      <div class="address">
        <div>{{ profile.pointLivraison.address.numeroEtNomVoie }}</div>
        {{ profile.pointLivraison.address.codePostal }}
        {{ profile.pointLivraison.address.commune.libelle }}
      </div>
    </td>
    <td class="font-weight-bold">
      {{ profile.technicalData.typeCompteur }}
    </td>
    <td class="font-weight-bold">
      <AppNumber :value="profile.transports[0].cdpAnalyse.consommationTotale" />
    </td>

    <SitePageTransportType
      :transportTypes="transportTypes"
      :transports="profile.transports"
    />

    <SitePageDetailsPower
      v-for="(temporalClass, i) in temporalClasses"
      :key="'temporalClass' + i"
      :transports="profile.transports"
      :temporalClass="temporalClass"
    />

    <SitePageConsumptionProfile :transports="profile.transports" />

    <SitePageOverviewDetail
      amountType="accoutingAndAdministration"
      :transports="transports"
    />

    <SitePageOverviewDetail
      amountType="infrastructuralPower"
      :transports="transports"
    />

    <SitePageOverviewDetail
      amountType="infrastructuralEnergy"
      :transports="transports"
    />

    <SitePageOverviewDetail amountType="tax" :transports="transports" />

    <SitePageOverviewDetail
      amountType="endpointServiceCostBudget"
      :transports="transports"
    />

    <SitePageOverviewDetail amountType="total" :transports="transports" />

    <SitePageOverviewDetail
      class="pr-2"
      amountType="benefit"
      :transports="transports"
      :showSign="true"
    />
  </tr>
</template>

<script>
  import AppNumber from '../../../app/AppNumber';
  import SitePageTransportType from '../SitePageTransportType';
  import SitePageOverviewDetail from '../overview/SitePageOverviewDetail';
  import SitePageDetailsPower from './SitePageDetailsPower';
  import SitePageConsumptionProfile from '../SitePageConsumptionProfile';
  import { eAcheminementType } from '../../../../services/TransportBudget.mapper';

  export default {
    name: 'SitePageDetailsRow',
    components: {
      AppNumber,
      SitePageTransportType,
      SitePageOverviewDetail,
      SitePageDetailsPower,
      SitePageConsumptionProfile,
    },
    props: {
      profile: {
        type: Object,
        Required: true,
      },
      temporalClasses: {
        type: Array,
        Required: true,
      },
    },
    computed: {
      transportTypes() {
        const transportTypes = [
          ...new Set(
            this.profile.transports.map(
              (transport) => transport.acheminementType
            )
          ),
        ];

        return transportTypes;
      },
      transports() {
        return this.profile.transports.map((transport) => {
          return {
            acheminementType: transport.acheminementType,
            accoutingAndAdministration:
              transport.budget.composanteComptage.amountExcludingVat +
              transport.budget.composanteGestion.amountExcludingVat,
            infrastructuralPower:
              transport.budget.composanteSoutirage.power.total,
            infrastructuralEnergy:
              transport.budget.composanteSoutirage.energy.total,
            tax: transport.budget.taxes.contributionTarifaireAcheminement
              .montantHt,
            endpointServiceCostBudget:
              transport.budget.endpointServiceCostBudget.amountExcludingVat,
            benefit: this.computeBenefit(transport.budget.montantHt),
            total: transport.budget.montantHt,
          };
        });
      },
    },
    methods: {
      computeBenefit(transportAmount) {
        const currentBudgetAmount = this.profile.transports.find(
          (e) => e.acheminementType === eAcheminementType.eCurrentAcheminement
        ).budget.montantHt;

        return currentBudgetAmount - transportAmount;
      },
    },
  };
</script>

<style lang="scss" scoped>
  td {
    font-size: 0.7em;
    text-align: center;
    color: var(--grey);
  }

  .address {
    font-size: 0.75em;
  }
</style>
